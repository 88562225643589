import BlogCards from "../components/blog/BlogCards";
import HeadlineBlogPage from "../components/blog/HeadlineBlogPage";
import RegistrationForm from "../components/contact/RegistrationForm";
import bgContact from "../components/contact/assets/contactPageBg.jpg"
import ContactUsCard from "../components/layout/ContactUsCard";
import ExploreSolution from "../components/layout/ExploreSolution";
import { useContextStorage } from "../utils/ContextStorage";
export default function Blog() {
    const { scrollLength } = useContextStorage();
  return (
    <>
      <div 
       
       className={` w-screen   ${scrollLength > 200 && " pt-[80px]"} `}>
        <HeadlineBlogPage/>
        <BlogCards/>
        <div className=" h-5"></div>
        <ExploreSolution/>
        <ContactUsCard/>
        <div className=" h-20"></div>
     </div>
    </>
  );
}
