import { motion } from "framer-motion";

export default function AboutUsIntro() {
  // Variants for the text animations
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  // Container variants to stagger the children animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <>
      <div className=" w-screen  flex justify-center items-center ">
        <div className="flex justify-between items-center w-full     lg:mt-14  max-w-screen-xl  ">
          <motion.div
            className="flex flex-col items-start  gap-3     w-full h-full"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="gap-2 flex flex-col w-full text-white font-bold text-[30px]  lg:text-5xl"
              variants={containerVariants}
            >
              <motion.h1
                variants={textVariants}
                className="  lg:min-w-[600px] text-center  py-4 px-2  lg:px-2"
              >
                Empowering <span className="text-[#E6293A]">Excellence</span> in
                the{" "}
                <span variants={textVariants} className="text-[#E6293A]">
                  Digital Era
                </span>
              </motion.h1>
            </motion.div>
            <motion.div
              className="text-sm text-gray-100  w-full flex flex-col items-start"
              variants={containerVariants}
            >
              <motion.h1
                className=" text-sm text-justify  px-5  lg:text-center w-full  lg:px-20"
                variants={textVariants}
              >
                Welcome to
                <span className="  font-bold px-1 ">
                  Toyo Digital Innovation
                </span>
                where innovation, expertise, and technology converge to shape
                the digital landscape. We are not just a company we are a
                catalyst for progress in a world where the digital age presents
                boundless opportunities. Our journey is characterized by a
                relentless pursuit of excellence, a passion for innovation, and
                a commitment to transforming the way businesses and individuals
                thrive in the digital era.
              </motion.h1>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
}
