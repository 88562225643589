import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import iso from "./assets/iso.png";
import cyber from "./assets/cyber.png";
import software from "./assets/software.png";
import imag2 from "./assets/web design.svg";
import software1 from "./assets/software.svg";
import isoSvg from "./assets/iso.svg";
import cyberSvg from "./assets/cyber.svg";

import home_page4 from "./assets/wepik-export-20231207123213AyEX.png";
import wave_for_heading from "./assets/wave_for_heading.svg";
const content = [
  {
    image: software1,
    title: `Empowering <span className="text-red-500">Your Vision</span> with Custom <span className="text-red-500">Software Solutions</span>`,
    description:
      "We serve as your reliable ally in the realm of custom software development. Recognizing the distinctiveness of your business, we appreciate that one-size-fits-all solutions may not always align with your precise requirements. Hence, we're dedicated to converting your concepts into customized software solutions, meticulously crafted to boost your success and operational efficiency.",
  },
  {
    image: home_page4,
    title: "Turning Your Mobile App Ideas into Reality",
    description:
      "We are your dedicated partners in making your mobile app dreams come true. Whether you have a revolutionary concept or an existing idea in need of a digital transformation, we're here to transform your vision into a reality. Discover the boundless potential of mobile application development with us.",
  },
  {
    image: isoSvg,
    title: "Fostering Excellence through ISO Management Solutions",
    description:
      "We stand as your reliable ally in harnessing the potential of ISO Management Solutions. Our primary objective is to furnish businesses with all-encompassing tools for Governance, Risk Management, Quality Management, and Audit, propelling them toward excellence, unwavering compliance, and heightened efficiency. Step into a realm where ISO standards are not merely fulfilled but consistently surpassed.",
  },
  {
    image: imag2,
    title: "Elevate Your Online Presence with Expert Web Design & Development",
    description:
      "We recognize the significance of a captivating online presence. Your website frequently serves as the initial interaction between your business and potential clients. It's the canvas where your brand takes shape and your narrative unfolds. This is why our commitment to website design and development services is unwavering, aimed at assisting you in leaving a lasting mark in the digital realm.",
  },
  {
    image: cyberSvg,
    title:
      "Elevate Your Network Configuration & Fortify Your Network Security ",
    description:
      "At Toyo Digital Innovation, our focus extends beyond innovation; we are dedicated to revolutionizing your digital infrastructure. Specializing in two fundamental pillars that underpin the digital age, our commitment lies in bolstering the security of your digital landscape.   ",
  },
];
export default function Service_products() {
  const [loadedStates, setLoadedStates] = useState(() =>
    Array(content.length).fill(false)
  );
  const refArray = useRef([]);
  refArray.current = [];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadedStates((prevStates) => {
              const newStates = [...prevStates];
              newStates[refArray.current.indexOf(entry.target)] = true;
              return newStates;
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    refArray.current.forEach((elem) => {
      if (elem) observer.observe(elem);
    });

    return () => observer.disconnect();
  }, []);

  const addToRefs = (elem) => {
    if (elem && !refArray.current.includes(elem)) {
      refArray.current.push(elem);
    }
  };

  return (
    <div className="flex flex-col mt-[40px] gap-10 justify-center items-center w-full h-full">
      {content.map((item, index) => (
        <>
          <motion.div
            initial={{ opacity: 0, y: -50 }} // Initial state of the card
            animate={{ opacity: 1, y: 0 }} // Animate to this state
            transition={{ duration: 0.5, delay: index * 0.2 }} // Timing
            key={index}
            className="w-full flex   justify-center items-center relative"
          >
            <div className="flex justify-center items-center z-10 w-full bg-transparent">
              <div
                ref={addToRefs}
                className={`block py-10 p-6  bg-[#101338] lg:bg-transparent transition-all hover:translate-y-2 -translate-y-5 transform duration-1000 border border-gray-200 rounded-lg shadow-2xl  
                ${
                  loadedStates[index]
                    ? "backdrop-filter backdrop-blur-[15px]"
                    : " bg-[#101338] lg:bg-gray-600 "
                }
               `}
              >
                <div className="  justify-center lg:flex hidden w-full max-w-screen-xl items-center">
                  {index % 2 !== 0 ? (
                    <>
                      <div className="block py-10 p-6  ">
                        {index === 0 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight w-[550px] text-[#101338]">
                              Empowering{" "}
                              <span className="text-[#101338]">
                                Your Vision
                              </span>{" "}
                              with Custom{" "}
                              <span className="text-[#101338]">
                                Software Solutions
                              </span>
                            </h5>
                          </>
                        )}
                        {index === 1 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight w-[550px] text-[#101338]">
                              Turning{" "}
                              <span className="text-[#101338]">
                                Your Mobile App
                              </span>{" "}
                              <span className="text-[#101338]">
                                into Reality
                              </span>{" "}
                              Ideas
                            </h5>
                          </>
                        )}
                        {index === 2 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight w-[550px] text-[#101338]">
                              Fostering{" "}
                              <span className="text-[#101338]">Excellence</span>{" "}
                              through{" "}
                              <span className="text-[#101338]">
                                ISO Management Solutions
                              </span>
                            </h5>
                          </>
                        )}
                        {index === 3 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5  font-bold tracking-tight w-[550px] text-[#101338]">
                              Elevate{" "}
                              <span className="text-[#101338]">
                                {" "}
                                Your Online Presence{" "}
                              </span>{" "}
                              through{" "}
                              <span className="text-[#101338]">
                                with Expert Web Design & Development{" "}
                              </span>
                            </h5>
                          </>
                        )}
                        <div className="h-1 w-1/2 bg-gray-400"></div>
                        <p className="font-normal pt-5 text-gray-200 w-[400px] text-justify">
                          {item.description}
                        </p>
                        <div className="px-4    py-[6px] hover:bg-[#ffffff] mt-9   border-[#E6293A] border w-fit rounded-md bg-[#E6293A] hover:text-[#E6293A] text-white">
                          <Link to="/contact" className="text-xl font-semibold">
                            Talk to our expert
                          </Link>
                        </div>
                      </div>{" "}
                      <div className="   w-full flex justify-center items-center rounded-lg">
                        <img
                          width={600}
                          className="rounded-lg  "
                          src={item.image}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      {index === 2 ? (
                        <>
                          <div className="   w-full flex justify-center items-center rounded-lg">
                            <img
                              className="rounded-lg h-[510px]  "
                              width={600}
                              src={item.image}
                              alt=""
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="   w-full flex justify-center items-center rounded-lg">
                            <img
                              className="rounded-lg  "
                              width={600}
                              src={item.image}
                              alt=""
                            />
                          </div>
                        </>
                      )}
                      <div
                        className={`block ${
                          index === 2 ? "py-0" : " py-10"
                        }  p-6 `}
                      >
                        {index === 0 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 pl-8 font-bold tracking-tight w-[550px] text-[#101338]">
                              Empowering{" "}
                              <span className="text-[#101338]">
                                Your Vision
                              </span>{" "}
                              with Custom{" "}
                              <span className="text-[#101338]">
                                Software Solutions
                              </span>
                            </h5>
                          </>
                        )}
                        {index === 1 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight w-[550px] text-[#101338]">
                              Turning{" "}
                              <span className="text-[#101338]">
                                Your Mobile App
                              </span>{" "}
                              <span className="text-[#101338]">
                                into Reality
                              </span>{" "}
                              Ideas
                            </h5>
                          </>
                        )}
                        {index === 2 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 pl-8 font-bold tracking-tight w-[550px] text-[#101338]">
                              Fostering{" "}
                              <span className="text-[#101338]">Excellence</span>{" "}
                              through{" "}
                              <span className="text-[#101338]">
                                ISO Management Solutions
                              </span>
                            </h5>
                          </>
                        )}
                        {index === 4 && (
                          <>
                            <h5 className="mb-2 text-3xl pb-5 pl-8 font-bold tracking-tight w-[550px] text-[#101338]">
                              Elevating Cybersecurity Excellence to New Heights
                            </h5>
                          </>
                        )}
                        <div className="h-1 w-1/2 bg-gray-400 ml-10 "></div>
                        <p className="font-normal pt-5 text-gray-200 w-[400px]  pl-10  text-justify">
                          {item.description}
                        </p>
                        <div className="px-4    py-[6px] hover:bg-[#ffffff] mt-9 ml-10    border-[#E6293A] border w-fit rounded-md bg-[#E6293A] hover:text-[#E6293A] text-white">
                          <Link to="/contact" className="text-xl font-semibold">
                            Talk to our expert
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className=" flex justify-center lg:hidden  flex-wrap w-full max-w-screen-xl items-center">
                  <div className="block py-2 ">
                    {index === 0 && (
                      <>
                        <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight  w-full text-[#ffff]">
                          Empowering{" "}
                          <span className="text-[#ffff]">Your Vision</span> with
                          Custom{" "}
                          <span className="text-[#ffff]">
                            Software Solutions
                          </span>
                        </h5>
                      </>
                    )}
                    {index === 1 && (
                      <>
                        <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight  w-full text-[#ffff]">
                          Turning{" "}
                          <span className="text-[#ffff]">Your Mobile App</span>{" "}
                          <span className="text-[#ffff]">into Reality</span>{" "}
                          Ideas
                        </h5>
                      </>
                    )}
                    {index === 2 && (
                      <>
                        <h5 className="mb-2 text-3xl pb-5 font-bold tracking-tight  w-full text-[#ffff]">
                          Fostering{" "}
                          <span className="text-[#ffff]">Excellence</span>{" "}
                          through{" "}
                          <span className="text-[#ffff]">
                            ISO Management Solutions
                          </span>
                        </h5>
                      </>
                    )}
                    {index === 3 && (
                      <>
                        <h5 className="mb-2 text-3xl pb-5  font-bold tracking-tight  w-full text-[#ffff]">
                          Elevate{" "}
                          <span className="text-[#ffff]">
                            {" "}
                            Your Online Presence{" "}
                          </span>{" "}
                          through{" "}
                          <span className="text-[#ffff]">
                            with Expert Web Design & Development{" "}
                          </span>
                        </h5>
                      </>
                    )}
                    {index === 4 && (
                      <>
                        <h5 className="mb-2 text-3xl pb-5  font-bold tracking-tight  w-full text-[#ffff]">
                          Elevating Cybersecurity Excellence to New Heights
                        </h5>
                      </>
                    )}        <div className="h-1 w-1/2 bg-gray-400"></div>
                        <div className="   w-full flex justify-center mt-10 items-center rounded-lg">
                            <img
                              className="rounded-lg  "
                              width={300}
                              src={item.image}
                              alt=""
                            />
                          </div>

            
                    <p className="font-normal pt-5 text-gray-200 w-full text-justify">
                      {item.description}
                    </p>
                    <div className="px-4    py-[6px] hover:bg-[#ffffff] mt-9   border-[#E6293A] border w-fit rounded-md bg-[#E6293A] hover:text-[#E6293A] text-white">
                      <Link to="/contact" className="text-xl font-semibold">
                        Talk to our expert
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={` absolute lg:flex hidden left-0  -z-10 w-full  justify-center items-center ${
                index % 2 === 0 ? "rotate-180  " : "  "
              }`}
            >
              <img width="2000" height={1} src={wave_for_heading} alt="" />
            </div>
          </motion.div>
        </>
      ))}
    </div>
  );
}
