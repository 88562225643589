import AboutToyo from "../components/about/AboutToyo";
import AboutUsIntro from "../components/about/AboutUsIntro";
import Clients from "../components/about/Clients";
import GloablReach from "../components/about/GlobalReach";
import PeopleSuccess from "../components/about/PeopleSuccess";
import Perks from "../components/about/Perks";
import VisionMission from "../components/about/VisionMisson";
import RegistrationForm from "../components/contact/RegistrationForm";
import bgContact from "../components/contact/assets/contactPageBg.jpg"
import ContactUsCard from "../components/layout/ContactUsCard";
import ExploreSolution from "../components/layout/ExploreSolution";
import { useContextStorage } from "../utils/ContextStorage";

export default function About() {
    const { scrollLength } = useContextStorage();
  return (
    <>
      <div 
       
      className={` w-screen pb-10   ${scrollLength > 200 && " pt-[80px]"} `}>
       <AboutUsIntro/>
<VisionMission/>
<AboutToyo/>
<div className=" w-full h-auto" >

<PeopleSuccess/>
</div>
<div className="   ">

<Clients  />
</div>
<Perks/>

 <GloablReach/>
<ExploreSolution/>
<ContactUsCard/>

 
      </div>
    </>
  );
}
