import erp from "../services/assets/portfolio/erp.png";
import crm from "../services/assets/portfolio/crm.png";
import uiux from "../services/assets/portfolio/uiux.png";
import hrms from "../services/assets/portfolio/hrms.png";
import ecommerce from "../services/assets/portfolio/ecommerce.png";
import mobile from "../services/assets/portfolio/mobile.png";
import web from "../services/assets/portfolio/web.png";
import microsoft from "../services/assets/portfolio/microsoft.png";
import cloudSupport from "../services/assets/portfolio/cloudSupport.png";
import iot from "../services/assets/portfolio/iot.png";
import school from "../services/assets/portfolio/school.png";
import hospital from "../services/assets/portfolio/hospital.png";
import firewall from "../services/assets/portfolio/firewall.png";
import network from "../services/assets/portfolio/network.png";
import cyber from "../services/assets/portfolio/cyber.png";
import cloudBilling from "../services/assets/portfolio/cloudBilling.png";

export default function ExploreSolution() {
  const solutionOptions = [
    { value: "ERP Solution", option: "ERP Solution", image: erp },
    { value: "CRM Solution", option: "CRM Solution", image: crm },
    { value: "UI & UX Design", option: "UI & UX Design", image: uiux },
    {
      value: "HRMS - Human Resource Management Solution",
      option: "HRMS - Human Resource Management Solution",
      image: hrms,
    },
    {
      value: "E-commerce Solution",
      option: "E-commerce Solution",
      image: ecommerce,
    },
    {
      value: "Mobile App (Android & iOS)",
      option: "Mobile App (Android & iOS)",
      image: mobile,
    },
    {
      value: "Website Design & Development",
      option: "Website Design & Development",
      image: web,
    },
    {
      value: "Microsoft Dynamic 365",
      option: "Microsoft Dynamic 365",
      image: microsoft,
    },
    {
      value: "Cloud Billing Software",
      option: "Cloud Billing Software",
      image: cloudBilling,
    },
    { value: "IoT/AI Application", option: "IoT/AI Application", image: iot },
    {
      value: "School Management Solution",
      option: "School Management Solution",
      image: school,
    },
    {
      value: "Hospital Management Solution",
      option: "Hospital Management Solution",
      image: hospital,
    },
    {
      value: "Firewall Solution",
      option: "Firewall Solution",
      image: firewall,
    },
    {
      value: "Learning Management System",
      option: "Learning Management System",
      image: network,
    },
    { value: "Cyber Security", option: "Cyber Security", image: cyber },
    { value: "Cloud Support", option: "Cloud Support", image: cloudSupport },
  ];
  return (
    <div className=" w-screen relative   my-4  z-40 mt-12">
      <div className=" w-full flex justify-center mt-10 items-center">
        <div className="flex flex-col justify-center items-center w-full">
          <h1 className="text-[40px] heading font-bold  pt-6 text-[#101338]  heading   relative ">
            Our Service Portfolio
            <div className="  absolute  -bottom-1 left-0 w-full  flex justify-center items-center ">
              <div className=" w-[100%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
            </div>
          </h1>
        </div>
      </div>
      <div className=" w-full flex flex-wrap px-10  lg:px-[100px]  justify-center  lg:justify-between mt-10 items-center gap-4 ">
        {solutionOptions.map((item, index) => {
          return (
            <div
              key={index}
              className="flex hover:-translate-y-3 gap-2   p-2 border border-[#34C7A3]   duration-500 cursor-default shadow-lg  justify-center items-center w-72 h-20     text-left   rounded-lg  "
            >
              <div className=" w-[30%] h-16   rounded-lg  bg-[#34c7a273]  ">
                <img
                  className=" w-full h-full object-cover"
                  src={item.image}
                  alt=""
                />
              </div>
              <div className=" w-[70%] h-16  flex items-center  rounded-lg    ">
                <h1 className="text-[#272727] text-left font-bold  text-sm ">
                  {item.value}
                </h1>
              </div>
            </div>
          );
        })}
      </div>

      <div className=" h-20"></div>
    </div>
  );
}
