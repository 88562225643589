import { motion } from "framer-motion";
import Headline from "../components/home/Headline";
import { useContextStorage } from "../utils/ContextStorage";
import ExploreSection from "../components/home/ExploreSection";
import ServiceHeading from "../components/home/ServiceHeading";
import ServiceProducts from "../components/home/ServiceProducts";
import ContactUsCard from "../components/layout/ContactUsCard";

export default function Home() {
  const { scrollLength } = useContextStorage();

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const slideUp = {
    hidden: { y: 100, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  };

  return (
    <>
      <div className={`${scrollLength > 200 && " pt-[80px]"}  w-[100vw]   `}>
        <motion.div
          className={`flex justify-center  items-center w-full  `}
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <Headline />
        </motion.div>
        <motion.div
          className="relative w-full     flex justify-center items-center"
          initial="hidden"
          animate="visible"
          variants={slideUp}
        >
          <ExploreSection />
        </motion.div>{" "}
        <motion.div
          className="  w-full   mt-10   flex justify-center items-center"
          initial="hidden"
          animate="visible"
          variants={slideUp}
        >
          <ServiceHeading />
        </motion.div>
        <motion.div
          className="  w-full flex justify-center items-center"
          initial="hidden"
          animate="visible"
          variants={slideUp}
        >
          <ServiceProducts />
        </motion.div>
        <div className=" h-10 "></div>
        <div className="  lg:pb-14 pb-10 ">
          <ContactUsCard />
        </div>
        <div className=" w-full flex justify-center items-center mb-8 pt-10  pb-8  bg-red-50">

        <div  className=" w-full flex justify-center items-center pr-4     " >
               <div className=" w-full flex justify-center items-center">
                <img src="https://zodiacmatctest.s3.amazonaws.com/startup-india-hub-logo-vector.png" alt="" />
               </div>
              </div>
        </div>
      </div>
    </>
  );
}
