import {motion} from "framer-motion";
import ServiceCard from "./ServiceCard";
import team from "./assets/team.png";
import solution from "./assets/solution.jpg";
import innovation from "./assets/innovation.jpg";
// import results from "./assets/result.jpg";
import results from "./assets/Proven_Result__1_-removebg-preview.png"
// import team from "./assets/team1.jpg";
// import solution from "./assets/solution1.jpg";
// import innovation from "./assets/innovation1.jpg";
// import results from "./assets/result1.jpg";

export default function WhyToyo(){
    const content =[
        {
            "image": team,
          "title": "Experienced Team",
          "description": "Our team comprises experts with a deep understanding of various industries, ensuring that we provide solutions that fit your specific needs."
        },
        {
            "image": solution,
          "title": "Custom Solutions",
          "description": "We understand that one size doesn't fit all. Our services are tailored to your unique business goals and challenges."
        },
        {
            "image": innovation,
          "title": "Innovation-Driven",
          "description": "We're committed to staying at the forefront of industry trends and innovations, so your business can evolve with the times."
        },
        {
            "image": results,
          "title": "Proven Results",
          "description": "Our track record of success speaks for itself. Many businesses have seen tangible improvements and growth through our services."
        }
      ]
      const getAnimationProps = (index) => ({
        initial: { y: 100, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        whileHover: { y:index!=="text" ? -20 : 0 },
        transition: {  duration: index === "text" ? 1 : 0.4 }
    });
    
    return  (<>
        <div className=" flex flex-col justify-center  gap-2   lg:gap-10  mt-14 items-center   w-full h-full ">
            <div className=" flex justify-center items-center w-full">
                <div className="flex flex-col justify-center items-center w-full  lg:mt-10">
                    <motion.h1 {...getAnimationProps("text")} className="text-3xl heading relative ">Why Toyo Digital Innovation ?
                    <div className="  absolute  -bottom-2 left-0 w-full  flex justify-center items-center ">
                  <div className=" w-[80%]  lg:w-[100%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
                </div>
                    </motion.h1>
                </div>
            </div>
            <div className=" w-full flex gap-10 flex-wrap  justify-center items-center">
                {
                        content.map((item,index)=>{
                            return(
                                 <motion.div {...getAnimationProps(index)} key={index} className="group flex p-5 rounded-md shadow-xl shadow-gray-300 border   max-w-[300px]  flex-col items-center">
                                {/* <div className="flex flex-col justify-between items-center w-full">
                                    <h1 className="text-xl font-bold  text-[#22477D] transition-all transform duration-800 underline-offset-8  underline">{item.title}</h1>
                                    <div className="text-sm text-gray-500 text-justify pt-8">{item.description}</div>
                                </div> */}
                                <ServiceCard 
                                image={item.image}
                                title={item.title} description={item.description} />
                            </motion.div>
                            
                            )
})
                }
            </div>
        </div>
    </>)
}