import { useEffect, useState } from "react";
import Dropdown from "./DropDown";
import LabelInputSmall from "./LabelInputSmall";
import ReusableButton from "./ReusableButton";
import MobileNumberInput from "./MoblieNumberInput";
import TextArea from "./Textarea";
import contactbg from "./assets/contactBackground.jpg";
import contactbg2 from "./assets/contactPageBg.jpg";
import ContactUsCard from "../layout/ContactUsCard";
import { message } from "antd";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

export default function RegistrationForm() {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationError, setOrganizationError] = useState("");

  const [jobRole, setJobRole] = useState("");
  const [jobRoleError, setJobRoleError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [countrCode, setCountryCode] = useState("+91");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [finalMobileNumber, setFinalMobileNumber] = useState("");
  useEffect(() => {
    setFinalMobileNumber("+"+ countrCode + whatsappNumber);
  }, [countrCode, whatsappNumber]);

  const [whatsappNumberError, setWhatsappNumberError] = useState("");

  const [solution, setSolution] = useState("");
  const [solutionError, setSolutionError] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const validateForm = () => {
    let isValid = true;

    if (!firstName.trim()) {
      setFirstNameError("First Name is required");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (!organization.trim()) {
      setOrganizationError("Organization is required");
      isValid = false;
    } else {
      setOrganizationError("");
    }

    if (!jobRole.trim()) {
      setJobRoleError("Job Role is required");
      isValid = false;
    } else {
      setJobRoleError("");
    }

    if (!country) {
      setCountryError("Country is required");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!businessEmail.trim()) {
      setBusinessEmailError("Business Email is required");
      isValid = false;
    } else {
      setBusinessEmailError("");
    }

    if (!whatsappNumber.trim()) {
      setWhatsappNumberError("Whatsapp Number is required");
      isValid = false;
    } else {
      setWhatsappNumberError("");
    }
    if (!solution) {
      setSolutionError("Solution is required");
      isValid = false;
    } else {
      setSolutionError("");
    }

    return isValid;
  };
 
  const [loading, setLoading] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // /api/contact_us
      fetch("https://toyodigitalinnovation.pythonanywhere.com/api/contact_us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          company_name: organization,
          job_profile: jobRole,
          country: country.option,
          business_email: businessEmail,
          mobile_number: finalMobileNumber,
          solution_name: solution.value,
          enquiry_description: enquiry,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setFirstName("");
          setLastName("");
          setOrganization("");
          setJobRole("");
          setCountry("");
          setBusinessEmail("");
          setWhatsappNumber("");
          setSolution("");
          setEnquiry("");
          message.success("Your message has been sent successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          message.error("Something went wrong", error.message);
          setLoading(false);
        });
    }
  };

  const solutionOptions = [
    { value: "ERP Solution", option: "ERP Solution" },
    { value: "CRM Solution", option: "CRM Solution" },
    { value: "UI & UX Design", option: "UI & UX Design" },
    {
      value: "HRMS - Human Resource Management Solution",
      option: "HRMS - Human Resource Management Solution",
    },
    { value: "E-commerce Solution", option: "E-commerce Solution" },
    {
      value: "Mobile App (Android & iOS)",
      option: "Mobile App (Android & iOS)",
    },
    {
      value: "Website Design & Development",
      option: "Website Design & Development",
    },
    { value: "Microsoft Dynamic 365", option: "Microsoft Dynamic 365" },
    { value: "Cloud Billing Software", option: "Cloud Billing Software" },
    { value: "IoT/AI Application", option: "IoT/AI Application" },
    {
      value: "School Management Solution",
      option: "School Management Solution",
    },
    {
      value: "Hospital Management Solution",
      option: "Hospital Management Solution",
    },
    { value: "Firewall Solution", option: "Firewall Solution" },
    { value: "Network Security Solution", option: "Network Security Solution" },
    { value: "Cyber Security", option: "Cyber Security" },
    { value: "Cloud Support", option: "Cloud Support" },
  ];

  const countryOptions = [
    { value: "1", option: "AFGHANISTAN" },
    { value: "2", option: "ALBANIA" },
    { value: "3", option: "ALGERIA" },
    { value: "4", option: "AMERICAN SAMOA" },
    { value: "5", option: "ANDORRA" },
    { value: "6", option: "ANGOLA" },
    { value: "7", option: "ANGUILLA" },
    { value: "8", option: "ANTARCTICA" },
    { value: "9", option: "ANTIGUA AND BARBUDA" },
    { value: "10", option: "ARGENTINA" },
    { value: "11", option: "ARMENIA" },
    { value: "12", option: "ARUBA" },
    { value: "13", option: "AUSTRALIA" },
    { value: "14", option: "AUSTRIA" },
    { value: "15", option: "AZERBAIJAN" },
    { value: "16", option: "BAHAMAS" },
    { value: "17", option: "BAHRAIN" },
    { value: "18", option: "BANGLADESH" },
    { value: "19", option: "BARBADOS" },
    { value: "20", option: "BELARUS" },
    { value: "21", option: "BELGIUM" },
    { value: "22", option: "BELIZE" },
    { value: "23", option: "BENIN" },
    { value: "24", option: "BERMUDA" },
    { value: "25", option: "BHUTAN" },
    { value: "26", option: "BOLIVIA" },
    { value: "27", option: "BOSNIA AND HERZEGOVINA" },
    { value: "28", option: "BOTSWANA" },
    { value: "29", option: "BOUVET ISLAND" },
    { value: "30", option: "BRAZIL" },
    { value: "31", option: "BRITISH INDIAN OCEAN TERRITORY" },
    { value: "32", option: "BRUNEI DARUSSALAM" },
    { value: "33", option: "BULGARIA" },
    { value: "34", option: "BURKINA FASO" },
    { value: "35", option: "BURUNDI" },
    { value: "36", option: "CAMBODIA" },
    { value: "37", option: "CAMEROON" },
    { value: "38", option: "CANADA" },
    { value: "39", option: "CAPE VERDE" },
    { value: "40", option: "CAYMAN ISLANDS" },
    { value: "41", option: "CENTRAL AFRICAN REPUBLIC" },
    { value: "42", option: "CHAD" },
    { value: "43", option: "CHILE" },
    { value: "44", option: "CHINA" },
    { value: "45", option: "CHRISTMAS ISLAND" },
    { value: "46", option: "COCOS (KEELING) ISLANDS" },
    { value: "47", option: "COLOMBIA" },
    { value: "48", option: "COMOROS" },
    { value: "49", option: "CONGO" },
    { value: "50", option: "CONGO, THE DEMOCRATIC REPUBLIC OF THE" },
    { value: "51", option: "COOK ISLANDS" },
    { value: "52", option: "COSTA RICA" },
    { value: "53", option: "COTE D'IVOIRE" },
    { value: "54", option: "CROATIA" },
    { value: "55", option: "CUBA" },
    { value: "56", option: "CYPRUS" },
    { value: "57", option: "CZECH REPUBLIC" },
    { value: "58", option: "DENMARK" },
    { value: "59", option: "DJIBOUTI" },
    { value: "60", option: "DOMINICA" },
    { value: "61", option: "DOMINICAN REPUBLIC" },
    { value: "62", option: "ECUADOR" },
    { value: "63", option: "EGYPT" },
    { value: "64", option: "EL SALVADOR" },
    { value: "65", option: "EQUATORIAL GUINEA" },
    { value: "66", option: "ERITREA" },
    { value: "67", option: "ESTONIA" },
    { value: "68", option: "ETHIOPIA" },
    { value: "69", option: "FALKLAND ISLANDS (MALVINAS)" },
    { value: "70", option: "FAROE ISLANDS" },
    { value: "71", option: "FIJI" },
    { value: "72", option: "FINLAND" },
    { value: "73", option: "FRANCE" },
    { value: "74", option: "FRENCH GUIANA" },
    { value: "75", option: "FRENCH POLYNESIA" },
    { value: "76", option: "FRENCH SOUTHERN TERRITORIES" },
    { value: "77", option: "GABON" },
    { value: "78", option: "GAMBIA" },
    { value: "79", option: "GEORGIA" },
    { value: "80", option: "GERMANY" },
    { value: "81", option: "GHANA" },
    { value: "82", option: "GIBRALTAR" },
    { value: "83", option: "GREECE" },
    { value: "84", option: "GREENLAND" },
    { value: "85", option: "GRENADA" },
    { value: "86", option: "GUADELOUPE" },
    { value: "87", option: "GUAM" },
    { value: "88", option: "GUATEMALA" },
    { value: "89", option: "GUINEA" },
    { value: "90", option: "GUINEA-BISSAU" },
    { value: "91", option: "GUYANA" },
    { value: "92", option: "HAITI" },
    { value: "93", option: "HEARD ISLAND AND MCDONALD ISLANDS" },
    { value: "94", option: "HOLY SEE (VATICAN CITY STATE)" },
    { value: "95", option: "HONDURAS" },
    { value: "96", option: "HONG KONG" },
    { value: "97", option: "HUNGARY" },
    { value: "98", option: "ICELAND" },
    { value: "99", option: "INDIA" },
    { value: "100", option: "INDONESIA" },
    { value: "101", option: "IRAN, ISLAMIC REPUBLIC OF" },
    { value: "102", option: "IRAQ" },
    { value: "103", option: "IRELAND" },
    { value: "104", option: "ISRAEL" },
    { value: "105", option: "ITALY" },
    { value: "106", option: "JAMAICA" },
    { value: "107", option: "JAPAN" },
    { value: "108", option: "JORDAN" },
    { value: "109", option: "KAZAKHSTAN" },
    { value: "110", option: "KENYA" },
    { value: "111", option: "KIRIBATI" },
    { value: "112", option: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF" },
    { value: "113", option: "KOREA, REPUBLIC OF" },
    { value: "114", option: "KUWAIT" },
    { value: "115", option: "KYRGYZSTAN" },
    { value: "116", option: "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
    { value: "117", option: "LATVIA" },
    { value: "118", option: "LEBANON" },
    { value: "119", option: "LESOTHO" },
    { value: "120", option: "LIBERIA" },
    { value: "121", option: "LIBYAN ARAB JAMAHIRIYA" },
    { value: "122", option: "LIECHTENSTEIN" },
    { value: "123", option: "LITHUANIA" },
    { value: "124", option: "LUXEMBOURG" },
    { value: "125", option: "MACAO" },
    { value: "126", option: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF" },
    { value: "127", option: "MADAGASCAR" },
    { value: "128", option: "MALAWI" },
    { value: "129", option: "MALAYSIA" },
    { value: "130", option: "MALDIVES" },
    { value: "131", option: "MALI" },
    { value: "132", option: "MALTA" },
    { value: "133", option: "MARSHALL ISLANDS" },
    { value: "134", option: "MARTINIQUE" },
    { value: "135", option: "MAURITANIA" },
    { value: "136", option: "MAURITIUS" },
    { value: "137", option: "MAYOTTE" },
    { value: "138", option: "MEXICO" },
    { value: "139", option: "MICRONESIA, FEDERATED STATES OF" },
    { value: "140", option: "MOLDOVA, REPUBLIC OF" },
    { value: "141", option: "MONACO" },
    { value: "142", option: "MONGOLIA" },
    { value: "143", option: "MONTSERRAT" },
    { value: "144", option: "MOROCCO" },
    { value: "145", option: "MOZAMBIQUE" },
    { value: "146", option: "MYANMAR" },
    { value: "147", option: "NAMIBIA" },
    { value: "148", option: "NAURU" },
    { value: "149", option: "NEPAL" },
    { value: "150", option: "NETHERLANDS" },
    { value: "151", option: "NETHERLANDS ANTILLES" },
    { value: "152", option: "NEW CALEDONIA" },
    { value: "153", option: "NEW ZEALAND" },
    { value: "154", option: "NICARAGUA" },
    { value: "155", option: "NIGER" },
    { value: "156", option: "NIGERIA" },
    { value: "157", option: "NIUE" },
    { value: "158", option: "NORFOLK ISLAND" },
    { value: "159", option: "NORTHERN MARIANA ISLANDS" },
    { value: "160", option: "NORWAY" },
    { value: "161", option: "OMAN" },
    { value: "162", option: "PAKISTAN" },
    { value: "163", option: "PALAU" },
    { value: "164", option: "PALESTINIAN TERRITORY, OCCUPIED" },
    { value: "165", option: "PANAMA" },
    { value: "166", option: "PAPUA NEW GUINEA" },
    { value: "167", option: "PARAGUAY" },
    { value: "168", option: "PERU" },
    { value: "169", option: "PHILIPPINES" },
    { value: "170", option: "PITCAIRN" },
    { value: "171", option: "POLAND" },
    { value: "172", option: "PORTUGAL" },
    { value: "173", option: "PUERTO RICO" },
    { value: "174", option: "QATAR" },
    { value: "175", option: "REUNION" },
    { value: "176", option: "ROMANIA" },
    { value: "177", option: "RUSSIAN FEDERATION" },
    { value: "178", option: "RWANDA" },
    { value: "179", option: "SAINT HELENA" },
    { value: "180", option: "SAINT KITTS AND NEVIS" },
    { value: "181", option: "SAINT LUCIA" },
    { value: "182", option: "SAINT PIERRE AND MIQUELON" },
    { value: "183", option: "SAINT VINCENT AND THE GRENADINES" },
    { value: "184", option: "SAMOA" },
    { value: "185", option: "SAN MARINO" },
    { value: "186", option: "SAO TOME AND PRINCIPE" },
    { value: "187", option: "SAUDI ARABIA" },
    { value: "188", option: "SENEGAL" },
    { value: "189", option: "SERBIA AND MONTENEGRO" },
    { value: "190", option: "SEYCHELLES" },
    { value: "191", option: "SIERRA LEONE" },
    { value: "192", option: "SINGAPORE" },
    { value: "193", option: "SLOVAKIA" },
    { value: "194", option: "SLOVENIA" },
    { value: "195", option: "SOLOMON ISLANDS" },
    { value: "196", option: "SOMALIA" },
    { value: "197", option: "SOUTH AFRICA" },
    { value: "198", option: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
    { value: "199", option: "SPAIN" },
    { value: "200", option: "SRI LANKA" },
    { value: "201", option: "SUDAN" },
    { value: "202", option: "SURINAME" },
    { value: "203", option: "SVALBARD AND JAN MAYEN" },
    { value: "204", option: "SWAZILAND" },
    { value: "205", option: "SWEDEN" },
    { value: "206", option: "SWITZERLAND" },
    { value: "207", option: "SYRIAN ARAB REPUBLIC" },
    { value: "208", option: "TAIWAN, PROVINCE OF CHINA" },
    { value: "209", option: "TAJIKISTAN" },
    { value: "210", option: "TANZANIA, UNITED REPUBLIC OF" },
    { value: "211", option: "THAILAND" },
    { value: "212", option: "TIMOR-LESTE" },
    { value: "213", option: "TOGO" },
    { value: "214", option: "TOKELAU" },
    { value: "215", option: "TONGA" },
    { value: "216", option: "TRINIDAD AND TOBAGO" },
    { value: "217", option: "TUNISIA" },
    { value: "218", option: "TURKEY" },
    { value: "219", option: "TURKMENISTAN" },
    { value: "220", option: "TURKS AND CAICOS ISLANDS" },
    { value: "221", option: "TUVALU" },
    { value: "222", option: "UGANDA" },
    { value: "223", option: "UKRAINE" },
    { value: "224", option: "UNITED ARAB EMIRATES" },
    { value: "225", option: "UNITED KINGDOM" },
    { value: "226", option: "UNITED STATES" },
    { value: "227", option: "UNITED STATES MINOR OUTLYING ISLANDS" },
    { value: "228", option: "URUGUAY" },
    { value: "229", option: "UZBEKISTAN" },
    { value: "230", option: "VANUATU" },
    { value: "231", option: "VENEZUELA" },
    { value: "232", option: "VIET NAM" },
    { value: "233", option: "VIRGIN ISLANDS, BRITISH" },
    { value: "234", option: "VIRGIN ISLANDS, U.S." },
    { value: "235", option: "WALLIS AND FUTUNA" },
    { value: "236", option: "WESTERN SAHARA" },
    { value: "237", option: "YEMEN" },
    { value: "238", option: "ZAMBIA" },
    { value: "239", option: "ZIMBABWE" },
    { value: "240", option: "SERBIA" },
    { value: "241", option: "ASIA PACIFIC REGION" },
    { value: "242", option: "MONTENEGRO" },
    { value: "243", option: "ALAND ISLANDS" },
    { value: "244", option: "BONAIRE, SINT EUSTATIUS AND SABA" },
    { value: "245", option: "CURACAO" },
    { value: "246", option: "GUERNSEY" },
    { value: "247", option: "ISLE OF MAN" },
    { value: "248", option: "JERSEY" },
    { value: "249", option: "KOSOVO" },
    { value: "250", option: "SAINT BARTHELEMY" },
    { value: "251", option: "SAINT MARTIN" },
    { value: "252", option: "SINT MAARTEN" },
    { value: "253", option: "SOUTH SUDAN" },
  ];

  return (
    <>
      {loading ? (
        <>
          <div className=" backdrop   flex justify-center items-center w-full">
            <CircularProgress color="primary" />
          </div>
          <style jsx global>{`
            .backdrop {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 999; // Adjust as needed
            }
          `}</style>
        </>
      ) : (
        <>
          <div
            className={` w-full max-w-screen-xl flex-wrap lg:flex-nowrap  bg-white  lg:rounded-l-lg    lg:rounded-lg shadow-lg   gap-5         lg:mt-20   flex justify-center    `}
          >
            <div
              style={{
                backgroundImage: `url(${contactbg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",

                width: "2000px",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                opacity: "0.9",
              }}
              className="   h-[550px] lg:h-[700px]    lg:rounded-l-lg    flex justify-center items-center "
            >
              <div className=" w-full h-full  lg:rounded-l-lg  bg-gray-900/40">
                <div className="flex flex-col justify-center mt-[20px] items-center w-full h-full">
                  <h1 className="text-[50px] font-semibold   text-white pl-8  text-left underline underline-offset-8 w-full    relative ">
                    Contact Us
                  </h1>
                  <div className="flex mt-4   flex-col justify-center items-start w-full">
                    <h1 className=" text-sm w-[80%]  font-semibold  text-start  pl-8   text-white   relative ">
                      Feel free to reach out and start a conversation! You can
                      rely on us for assistance, and getting in touch with us is
                      just a few clicks away.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="  flex flex-col">
              <div className="flex flex-col justify-center gap-16 mt-14 items-center">
                <div className="w-full flex flex-wrap   justify-center items-center">
                  <div className="w-full flex flex-wrap   lg:gap-5 gap-1 justify-center items-center">
                    {/* First Name */}
                    <LabelInputSmall
                      mandatory={true}
                      label="First Name"
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      error={firstNameError}
                      onChange={(e) => setFirstName(e.target.value)}
                    />

                    {/* Last Name */}
                    <LabelInputSmall
                      label="Last Name"
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />

                    {/* Organization */}
                    <LabelInputSmall
                      label="Organization"
                      type="text"
                      placeholder="Company Name"
                      value={organization}
                      error={organizationError}
                      mandatory={true}
                      onChange={(e) => setOrganization(e.target.value)}
                    />

                    {/* Job Role */}
                    <LabelInputSmall
                      label="Job Role"
                      type="text"
                      placeholder="Job Role"
                      value={jobRole}
                      error={jobRoleError}
                      mandatory={true}
                      onChange={(e) => setJobRole(e.target.value)}
                    />

                    {/* Country Dropdown */}
                    <Dropdown
                      width={"full"}
                      label={"Country"}
                      mandatory={true}
                      data={countryOptions}
                      displayValue="option"
                      returnValue="option"
                      placeholder="Select country"
                      selectedOption={country}
                      setSelectedOption={setCountry}
                      error={countryError}
                    />

                    {/* Business Email */}
                    <LabelInputSmall
                      label="Business Email"
                      type="text"
                      width={"full"}
                      placeholder="Email"
                      value={businessEmail}
                      error={businessEmailError}
                      onChange={(e) => setBusinessEmail(e.target.value)}
                    />

                    {/* Whatsapp Number */}
                    <MobileNumberInput
                      mandatory={true}
                      label="Whatsapp Number"
                      phoneNumber={whatsappNumber}
                      setPhoneNumber={setWhatsappNumber}
                      error={whatsappNumberError}
                      countrCode={countrCode}
                      setCountryCode={setCountryCode}
                    />

                    {/* Solution Dropdown */}
                    <Dropdown
                      label="Solution Name"
                      mandatory={true}
                      data={solutionOptions}
                      displayValue="value"
                      returnValue="option"
                      placeholder="Select solution"
                      selectedOption={solution}
                      setSelectedOption={setSolution}
                      error={solutionError}
                    />

                    {/* Enquiry TextArea */}
                    <TextArea
                      label="Describe Your Enquiry"
                      type="text"
                      placeholder="Write something"
                      value={enquiry}
                      onChange={(e) => setEnquiry(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-center lg:pb-0 pb-10 mt-5 lg:mt-0 items-center">
                <div className="flex flex-col w-full h-full justify-center items-center">
                  <div className="  w-full  px-14 ">
                    <button
                      onClick={onSubmit}
                      className=" bg-[#1849A9] w-full  rounded-md text-white  font-semibold h-10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="lg:h-16 h-10"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
