import { Link } from "react-router-dom";
import  image8 from "./assets/blog card images/1678518381934 (1).jpeg";
import image7 from "./assets/blog card images/1678859367947 (1).jpeg";
import image6 from "./assets/blog card images/1679308341569 (1).jpeg";
import image5 from "./assets/blog card images/1679742374430 (1).jpeg";
import image4 from "./assets/blog card images/1681110061935 (1).jpeg";
import image3 from "./assets/blog card images/1683694749972 (1).jpeg";
import image2 from "./assets/blog card images/1688362746809 (1).jpeg";
import image1 from "./assets/blog card images/1695319212372 (1).jpeg";
import { Card } from "antd";

export default function BlogCards() {
  const blogData = [
    {
      title:
        "Optimising Efficiency and Confidence: The Impact of Internal Audit Software",
      summary:
        "In the dynamic landscape of modern business, adaptation and innovation are the keys to survival and success. One pivotal element that has undergone a significant evolution is internal audit, with the introduction of sophisticated software tools.",
      author: "Veda Vyas Harikumar",
      readTime: "3 min read",
      image: image1,
      link: "/blog/optimising-efficiency-and-confidence-the-impact-of-internal-audit-software",
    },
    {
      title:
        "How People Supply Chain Management Solution Help Businesses to Grow?",
      summary:
        "In today's competitive business landscape, effective supply chain management has become crucial for companies looking to grow and succeed. A key component in this is the people supply chain management solutions.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image2,
        link: "/blog/how-people-supply-chain-management-solution-help-businesses-to-grow",
    },
    {
      title: "6 Ways E-commerce Websites Help Businesses Grow",
      summary:
        "E-commerce has become an integral part of our daily lives. With the rise of online shopping, e-commerce websites have become a vital tool for businesses seeking growth and expansion.",
      author: "Veda Vyas Harikumar",
      readTime: "3 min read",
      image: image3,
        link: "/blog/6-ways-e-commerce-websites-help-businesses-grow",
    },
    {
      title: "The Benefits of Workflow Automation for Business Growth",
      summary:
        "Workflow automation has emerged as a key tool for businesses seeking to streamline operations, improve efficiency, and reduce costs. By automating repetitive tasks, companies can focus on growth and innovation.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image4,
        link: "/blog/the-benefits-of-workflow-automation-for-business-growth",
    },
    {
      title: "How Artificial Intelligence can Help Businesses Grow",
      summary:
        "Artificial Intelligence (AI) is transforming the way businesses operate and make decisions. By using machine learning algorithms and deep neural networks, AI provides insights and efficiencies previously unattainable.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image5,
        link: "/blog/how-artificial-intelligence-can-help-businesses-grow",
    },
    {
      title: "We Build Customized Solution for Building Successful Companies",
      summary:
        "Customized software has become a key tool for businesses in a variety of industries. By creating software tailored to specific needs, businesses can streamline operations and enhance productivity.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image6,
        link: "/blog/we-build-customized-solution-for-building-successful-companies",
    },
    {
      title:
        "Process automation helps firms reduce typical manual work while boosting output and overall effectiveness",
      summary:
        "Workflow automation is the process of automating workflow processes using computer software. It has become a crucial part of business operations in recent times, enhancing efficiency and output.",
      author: "Veda Vyas Harikumar",
      readTime: "1 min read",
      image: image7,
        link: "/blog/process-automation-helps-firms-reduce-typical-manual-work-while-boosting-output-and-overall-effectiveness",
    },
    {
      title: "Digital Transformation",
      summary:
        "With the move to digital transformation, companies are now facing the challenge of replacing their manual processes with automated ones. This shift is driving a new era of efficiency and productivity.",
      author: "Veda Vyas Harikumar",
      readTime: "1 min read",
      image: image8,
        link: "/blog/digital-transformation",
    },
    
  ];

  return (
    <>
      <div className=" w-full flex justify-center items-center    lg:mt-5 ">
        <div className=" w-full flex  flex-wrap justify-around gap-10 max-w-screen-xl">
          {blogData.map((card, index) => (
            <Link 
            to={card.link}
        
                key={index}
            >
            
     
            <Card
          
              hoverable
              style={{ width: 340 }}
              cover={
                <img
                  alt={card.title}
                  src={card.image}
                  className="object-cover h-48 w-full"
                />
              }
            >
              <Card.Meta
                title={card.title}
                description={
                  <>
                    <p className="text-sm text-gray-600">{card.summary}</p>
                    <p className="text-xs text-gray-500 pt-1">{`By ${card.author} • ${card.readTime}`}</p>
                  </>
                }
              />
            </Card>       </Link>
          ))}
             <div
 
                className=" invisible w-[340px]"
             
               
            >
             
            </div>
        </div>
      </div>
    </>
  );
}
