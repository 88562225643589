

export default function Service_heading() {

  return (
    <div>
      <div className="flex justify-center items-center max-w-screen-xl   w-full">
        <div className=" flex flex-col gap-4">
          <div className=" flex w-full justify-center items-center">
            <h1 className="text-[26px] heading
              font-bold text-[#101338] relative ">
            Our Service Portfolio
              <div className=" h-[2px] absolute -bottom-1 left-0 w-full bg-[#EE3231] rounded-2xl "></div>
            </h1>
          </div>
          {/* <div className=" flex w-full justify-center pt-1 items-center">
            <h1 className=" lg:text-[23px] font-sans  text-center text-sm   text-[#101338] relative ">
              Propelling Your Digital Enterprise Towards Unstoppable Growth and
              Innovation!
            </h1>
          </div> */}
          
        </div>
      </div>
 
    </div>
  );
}
