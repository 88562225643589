import { motion, AnimatePresence } from "framer-motion";
import heaxagon from "./assets/methology/hexagon-svgrepo-com.svg";
import idea from "./assets/methology/idea.svg";
import design from "./assets/methology/design.svg";
import arrow from "./assets/methology/arrow.svg";
import development from "./assets/methology/development.svg";
import deploy from "./assets/methology/deploy.svg";
import maintainance from "./assets/methology/maintainance.svg";
import { useInView } from "react-intersection-observer";

export default function HexagonalCard() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Element is considered in view after 10% is visible
  });
  const content = [
    {
      title: "Strategic Planning & Idea Generation",
      image: idea,
    },
    {
      title: "Design & Prototyping",
      image: design,
    },
    {
      title: "Development & Innovation",
      image: development,
    },
    {
      title: "Testing & Deployment",
      image: deploy,
    },
    {
      title: "Maintainance & Support",
      image: maintainance,
    },
  ];
  const variants = {
    initial: { opacity: 0, x: 34 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -34 },
  };
  return (
    <div
      ref={ref}
      className="flex justify-center lg:flex-nowrap flex-wrap  lg:gap-0 gap-20 items-center  pb-20      "
    >
      <AnimatePresence>
        {content.map((item, index) => (
          <motion.div
            key={item.title}
            variants={variants}
            initial="initial"
            animate={inView ? "animate" : "initial"}
            exit="exit"
            transition={{ duration: 1, delay: index * 0.4 }}
            className=" "
          >
            <div className=" flex  lg:hover:-translate-y-5  transform  transition-all duration-500">
              <div
                key={index}
                className=" w-full flex justify-center items-center "
              >
                <div className=" relative">
                  <img
                    className=" hidden lg:block"
                    width={index === 4 ? 140 : 200}
                    src={heaxagon}
                    alt=""
                  />
                  <img
                    className=" lg:hidden"
                    width={index === 4 ? 200 : 200}
                    src={heaxagon}
                    alt=""
                  />
                  <div className=" absolute overflow-hidden      -top-1 left-0  flex justify-center items-center w-full h-full ">
                    <img width={70} src={item.image} alt="" />
                  </div>
                  <div className=" absolute  -bottom-10 lg:-bottom-16 w-full left-0     flex-col flex items-center ">
                    <h1
                      className={`text-[#000000] text-center font-bold text-sm ${
                        index === 1 && "pb-3"
                      }`}
                    >
                      {item.title}
                    </h1>
                  </div>
                </div>
              </div>
              {index !== content.length - 1 && (
                <>
                  <div className=" w-full hidden lg:flex  justify-center items-center">
                    <img src={arrow} alt="" />
                  </div>
                </>
              )}
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
