import ContactHeading from "../components/contact/ContactHeading";
import RegistrationForm from "../components/contact/RegistrationForm";
import bgContact from "../components/contact/assets/contactPageBg.jpg";
import ContactUsCard from "../components/layout/ContactUsCard";
import ExploreSolution from "../components/layout/ExploreSolution";
import { useContextStorage } from "../utils/ContextStorage";
export default function Contact() {
  const { scrollLength } = useContextStorage();
  return (
    <>
      <div
        className={` w-screen pb-10 bg-[#10133800]  ${
          scrollLength > 200 && " pt-[80px]"
        } `}
      >
        <ContactHeading />
        <div className=" w-full flex justify-center mb-1 lg:mb-10 items-center">
          <RegistrationForm />
        </div>
        <div className=" pb-5">
          <ExploreSolution />
          <ContactUsCard />
        </div>
      </div>
    </>
  );
}
