import React from "react";
import isoAnimated from './assets/iso/isoAnimated1.svg';
import audit from "./assets/iso/Audit_management_service-removebg-preview.png"
import grc from "./assets/iso/GRC_Solution__1_-removebg-preview.png"
import safety from "./assets/iso/23221552_risk_management_03-removebg-preview.png"
import quality from "./assets/iso/QUALITY_MANAGEMENT-removebg-preview.png"
import isobg from "./assets/iso/isobg.png"
function IsoCard({}) {
  const content = [
    { 
      image : audit,
      title: "Internal Audit Management Solution",
      description:
        "Our Internal Audit Management Solution streamlines your audit processes, ensuring compliance, accuracy, and efficiency. With our user-friendly platform, you can confidently manage, track, and report on all internal audit activities, enhancing your organization's risk management and overall operational performance.",
    },
    {
      image : grc,
      title: "GRC- Governance Risk & Compliance",
      description:
        "Discover a robust GRC (Governance, Risk, and Compliance) solution that ensures comprehensive management and oversight of your organization's operations, mitigating risks and optimizing compliance. Our GRC solution empowers your business with the tools and insights needed to proactively manage governance, navigate potential risks, and stay in compliance with industry standards.",
    },
    {
      image : safety,
      title: "Enterprise Risk Management Solution ISO 31000",
      description:
        "Our Enterprise Risk Management Solution provides a comprehensive approach to identify, assess, and mitigate potential risks, safeguarding your business from unforeseen challenges. With our tailored strategies and advanced tools, we empower your organization to make informed decisions and ensure long-term resilience",
    },
    {
      image : quality,
      title: "QMS- Quality Management System  ISO 9001",
      description:
        "Our QMS solution is the key to elevating your business's quality standards. Streamline processes, enhance efficiency, and ensure top-tier quality with our comprehensive Quality Management System.",
    },
  ];
  return (
    <div className="w-full flex flex-wrap justify-center items-center gap-10  ">
 
      {content.map((item, index) => (
        <div
          key={index}
          className="  h-[350px] relative  group  w-[300px] bg-[#101338] border-[2px]    rounded-xl overflow-hidden group hover:-translate-y-3 duration-700 flex justify-start items-start"

        >
          
          <div className="     bg-opacity-0 h-full  p-6 flex flex-col justify-start items-center  ">
            
            <h5 className="mb-2 text-lg     duration-700  transition-all  font-bold tracking-tight text-center text-white underline underline-offset-4">
              {item.title}
            </h5>
            <p className={`font-normal  text-sm text-justify text-white ${index===3 ? " pt-6 " :" mt-4"}`}>
              {item.description}
            </p>
          </div>
          {/* <div className=" absolute flex w-full justify-center items-center opacity-40  bg-black  h-full top-0 left-0">
          <img
            className=" w-full h-full object-cover"
              src={isobg}
            alt=""
          />
          </div> */}
        </div>
      ))}
    </div>
  );
}

export default IsoCard;