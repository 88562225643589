import HeadlineServicePage from "../components/services/HeadlineServicePage";
import IsoManagement from "../components/services/IsoManagement";
import ServicePortfolio from "../components/services/ServicePortfolio";
import SoftwareMethology from "../components/services/SoftwareMethology";
import WhyToyo from "../components/services/WhyToyo";
import backgroundForServicePage from "../assets/backgroundForServicePage.jpg";
import ContactUsCard from "../components/layout/ContactUsCard";
import { useContextStorage } from "../utils/ContextStorage";
export default function Services() {
  const { scrollLength } = useContextStorage();
  return (
    <div 
       
    className={` w-screen   ${scrollLength > 200 && " pt-[80px]"} `}>
      <HeadlineServicePage />
      <WhyToyo />
      <ServicePortfolio />
      <SoftwareMethology />
      <IsoManagement />
      <div className=" h-20"></div>
      <ContactUsCard />
      <div className=" h-20"></div>
    </div>
  );
}
