import { motion } from "framer-motion";
import HexagonalCard from "./HexagonalCard";
export default function SoftwareMethology() {
 
  return (
    <div className=" flex flex-col justify-center gap-1  lg:gap-16 mt-20 items-center      w-screen bg-[#d7d7d7da] h-full  lg:h-[420px] ">
      <div className=" flex justify-center items-center w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="text-[30px] font-semibold text-gray-900  relative heading"
          >
         Software Development Methodology

            <div className="  absolute  -bottom-1 left-0 w-full  flex justify-center items-center ">
                  <div className=" w-[100%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
                </div>
          </motion.h1>
        </div>
      </div>
      <div className=" w-full flex     flex-wrap max-w-[1350px] justify-center items-center">
      <HexagonalCard/>
      </div>
    </div>
  );
}
