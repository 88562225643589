
import React, { createContext, useContext, useEffect, useState } from 'react';

 
const ContextStorageContext = createContext();

 
export function ContextStorageProvider({ children }) {
    const [scrollLength, setScrollLength] = useState(0);
    const handleScroll = () => {
        const currentScrollLength = window.scrollY;
        setScrollLength(currentScrollLength);
      };
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 
 
  return (
    <ContextStorageContext.Provider value={{ scrollLength,setScrollLength  }}>
      {children}
    </ContextStorageContext.Provider>
  );
}

export function useContextStorage() {
  return useContext(ContextStorageContext);
}
