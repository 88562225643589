import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Layout from "./layout/Layout";
import { ContextStorageProvider } from "./utils/ContextStorage";
import ImageToBase64 from "./layout/test";
import BlogDetailsPage from "./pages/BlogDetails";
import NotFoundPage from "./pages/404Page";
function App() {
  return (
    <ContextStorageProvider>
      <div className="relative">
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/image" element={<ImageToBase64 />} />
              <Route path="/blog/:postId" element={<BlogDetailsPage />} /> 

            </Routes>
          </Layout>
        </Router>
      </div>
    </ContextStorageProvider>
  );
}

export default App;
