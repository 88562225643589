import CountryCodeDropdown from "./CountryCodeDropdown"

export default function MobileNumberInput({ width,
  height,
  phoneNumber,
  setPhoneNumber,
  mandate,
  widthFull,
  error, readOnly,
  smallPlaceholder,
  disabled,
  countryCode,
  setCountryCode,
  ...others
}) {
  if (!width) {
    width = "300px"
  }
  width = `${width}px`
  if (widthFull) {
    width = "100%"
  }
  if (!height) {
    height = "40"
  } if (mandate === undefined) {
    mandate = true
  }
 


  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Remove all non-digit characters and split the input into groups of 5 digits
    const cleanedInput = inputValue.replace(/\D/g, "").slice(0, 10);
    const formattedInput = cleanedInput.replace(/(\d{5})/, "$1 ");

    setPhoneNumber(formattedInput.trim());
  };

  return (
    <div className="flex relative flex-col">
      <span className={`${smallPlaceholder ? " text-sm pb-1" : " text-sm pb-2"}   leading-5 text-[#344054] pt-1 `}>
        Mobile number {mandate && <span className="text-[#D92D20]">*</span>}
      </span>
      <div className={`relative flex items-center border ${error ? "border-[#F04438]" : "border-inputBorderGray"}  rounded-lg justify-center w-[300px] h-full mb-2`}>
        <span className="  inset-y-0 left-0 flex items-center  text-textBlack font-normal">
        <CountryCodeDropdown 
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
        </span>
        <input
          type="tel" // Use type "tel" for phone numbers
          value={phoneNumber}
          readOnly={readOnly}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-[300px]  h-9  text-textBlack pl-2  text-sm placeholder-textGray  border-0 outline-none ${readOnly && "cursor-default"}  spin-button-none rounded-[8px] border   pl-auto   outline-none ${disabled && "bg-[#EEEEEE]"} ${readOnly ? "cursor-default" : "focus:border-[#1849A9]"} placeholder-gray-400  text-black pb-[1px] font-normal`}
          placeholder=" "
          {...others}
        />
      </div>
      {
        error && (
          <div className=" absolute -bottom-3 font-normal text-[#F04438] text-[13px] text-left">
            {error}
          </div>
        )
      }
    </div>
  );
}
