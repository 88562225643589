import React from "react";
import video from "./assets/video.mp4";
import { Card } from "antd";

export default function PeopleSuccess() {
  return (
    <>
    
    <div className="  w-screen my-20 hidden lg:flex  justify-center items-center h-auto">
      <div className=" max-w-screen-xl px-10  w-full">
        <div className=" shadow-xl bg-[#ffffff] py-5  rounded-lg flex-wrap flex justify-center items-center w-full pr-10">
          <div className="flex-1 p-8">
            <h2 className="text-4xl font-bold text-left mb-1 heading ">
              A Vital Component for Achieving Success in Any Business
            </h2>
            <div className=" w-full flex justify-center items-center pl-10">
              <p className="  text-justify text-sm pr-16 pt-4">
                Toyo Digital Innovation is a prominent player in the field of
                technology-driven business performance enhancement. Drawing on
                our versatile expertise across diverse sectors, we empower
                businesses to enhance their operations. Our central focus
                revolves around optimizing business processes, harnessing the
                potential of people, leveraging technology, and mitigating
                operational risks. We have crafted specialized,
                easy-to-integrate solutions that seamlessly elevate any
                business. These intelligent solutions result from our extensive
                industry experience and adherence to best practices.
              </p>
            </div>
          </div>
          <div className="flex-1">
            <video
              src={video}
              autoPlay
              muted
              loop
              className="w-full h-full rounded-lg"
            ></video>
          </div>
        </div>
      </div>
    </div>
    
    <div className="  w-screen my-20 flex lg:hidden  justify-center items-center h-auto">
      <div className=" max-w-screen-xl px-2 h-auto w-full">
        <Card className="   bg-[#ffffff] py-5  rounded-lg flex-wrap flex justify-center items-center w-full  ">
          <div className="  ">
            <h2 className="text-4xl font-bold text-center mb-1 heading ">
              A Vital Component for Achieving Success in Any Business
            </h2>
            <div className="flex-1 mt-10 mb-4">
            <video
              src={video}
              autoPlay
              muted
              loop
              className="w-full h-full rounded-lg"
            ></video>
          </div>
            <div className=" w-full flex justify-center items-center ">
              <p className="  text-justify text-sm   pt-4">
                Toyo Digital Innovation is a prominent player in the field of
                technology-driven business performance enhancement. Drawing on
                our versatile expertise across diverse sectors, we empower
                businesses to enhance their operations. Our central focus
                revolves around optimizing business processes, harnessing the
                potential of people, leveraging technology, and mitigating
                operational risks. We have crafted specialized,
                easy-to-integrate solutions that seamlessly elevate any
                business. These intelligent solutions result from our extensive
                industry experience and adherence to best practices.
              </p>
            </div>
          </div>
       
        </Card>
      </div>
    </div>
    </>
  );
}
