import React, { useState } from 'react';

function ImageToBase64() {
  const [base64String, setBase64String] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBase64String(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className=' w-full flex justify-center items-center h-screen  bg-red-400'>
      <input type="file" onChange={handleFileChange} />
      {base64String && (
        <div>
          <p>Base64 Encoded String:</p>
          <textarea value={base64String} readOnly />
        </div>
      )}
    </div>
  );
}

export default ImageToBase64;
