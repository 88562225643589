
import globalReach from "./assets/gloablReach/World Map Animation Final.mp4"

export default function GloablReach() {
 

  
  return (
    <>
      <section className="perks-benefits mt-16" id="proactive">
        <h3 className=' relative'>
                Our Global Reach
            <div className="  absolute -bottom-1 left-0 w-full  flex justify-center items-center ">
                  <div className=" w-[14%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
                </div>
            </h3>
            <div className="w-full flex justify-center items-center">
          <div className="flex gap-10 w-full max-w-screen-xl">
                <video src={globalReach} muted loop autoPlay></video>
          </div>
        </div>
      </section>
    </>
  );
}