import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
export default function HeadlineBlogDetailsPage() {
    const blogData = [
        {
          title:        "Optimising Efficiency and Confidence: The Impact of Internal Audit Software",
          summary:
            "In the dynamic landscape of modern business, adaptation and innovation are the keys to survival and success. One pivotal element that has undergone a significant evolution is internal audit, with the introduction of sophisticated software tools.",
          author: "Veda Vyas Harikumar",
          readTime: "3 min read",
          
          link: "/blog/optimising-efficiency-and-confidence-the-impact-of-internal-audit-software",
          details:
           [
            {
              title: "Optimising Efficiency and Confidence: The Impact of Internal Audit Software",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "September 21, 2023",
              intro: "In the dynamic landscape of modern business, adaptation and innovation are the keys to survival and success. This article explores how Internal Audit Software is revolutionizing the way organizations safeguard their assets, ensure compliance, and navigate the intricate web of risks in today's business world."
            },
            {
              section: "The Evolution of Internal Auditing",
              content: "Traditionally associated with paperwork and manual processes, internal auditing has been transformed by Internal Audit Software, introducing new levels of efficiency and accuracy."
            },
            {
              section: "What Is Internal Audit Software?",
              content: "Internal Audit Software, or IAS, streamlines the auditing process with features like automated data analysis, real-time monitoring, risk assessment, and customizable reporting."
            },
            {
              section: "Benefits Galore",
              subSections: [
                {
                  subSectionTitle: "Enhanced Efficiency",
                  details: "Automates repetitive tasks, freeing up auditors for strategic activities."
                },
                {
                  subSectionTitle: "Data-Driven Insights",
                  details: "Leverages data analytics for identifying trends and risks."
                },
                {
                  subSectionTitle: "Real-Time Monitoring",
                  details: "Tracks key metrics continuously for prompt issue resolution."
                },
                {
                  subSectionTitle: "Improved Compliance",
                  details: "Simplifies compliance management with relevant guidelines."
                },
                {
                  subSectionTitle: "Comprehensive Reporting",
                  details: "Offers customizable reporting for internal and external communication."
                }
              ]
            },
            {
              section: "Choosing the Right Internal Audit Software",
              content: "Important factors include scalability, ease of use, integration capabilities, and vendor support. Aligning the software with organizational needs is crucial."
            },
            {
              section: "The Future of Internal Auditing",
              content: "Advancements in AI, blockchain, and integration promise to further enhance internal auditing, making Internal Audit Software a vital asset for modern businesses."
            },
            {
              conclusion: "Internal Audit Software has become an indispensable tool in modern business operations, driving efficiency, compliance, and strategic insights."
            }
          ]      
        },
        {
          title:        "How People Supply Chain Management Solution Help Businesses to Grow?",
          summary:
            "In today's competitive business landscape, effective supply chain management has become crucial for companies looking to grow and succeed. A key component in this is the people supply chain management solutions.",
          author: "Veda Vyas Harikumar",
          readTime: "2 min read",
          
          link: "/blog/how-people-supply-chain-management-solution-help-businesses-to-grow",
          details:
         [
            {
              title: "How People Supply Chain Management Solution Help Businesses to Grow?",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "July 3, 2023",
              intro: "In today's competitive business landscape, effective supply chain management is crucial for growth and success. This article explores how integrating people into supply chain management enhances efficiency, productivity, and drives business growth."
            },
            {
              section: "Improved Collaboration and Communication",
              content: "People supply chain management systems enhance business growth through improved collaboration and communication. Effective channels between employees, suppliers, and customers streamline operations, reduce delays, and ensure timely delivery, leading to increased customer satisfaction and business growth."
            },
            {
              section: "Nurturing Talent within the Organization",
              content: "These systems enable companies to identify and nurture talent, offering training programs, career development, and performance evaluations. Skilled, motivated employees increase productivity, quality, and innovation, essential for business growth in dynamic markets."
            },
            {
              section: "Adaptability to Changing Market Conditions",
              content: "People supply chain management systems help businesses adapt to market shifts, optimize inventory, and adjust production. This agility allows for seizing opportunities and mitigating risks, fostering sustained growth in volatile markets."
            },
            {
              conclusion: "People supply chain management systems are crucial in driving business growth. Focusing on communication, talent development, and adaptability, they optimize supply chain operations, enhance operational efficiency, fuel innovation, and customer satisfaction, leading to sustainable growth."
            }
          ],      
        },
        {
          title: "6 Ways E-commerce Websites Help Businesses Grow",
          summary:
            "E-commerce has become an integral part of our daily lives. With the rise of online shopping, e-commerce websites have become a vital tool for businesses seeking growth and expansion.",
          author: "Veda Vyas Harikumar",
          readTime: "3 min read",
          
          link: "/blog/6-ways-e-commerce-websites-help-businesses-grow",
          details:   [
            {
              title: "6 Ways E-commerce Websites Help Businesses Grow",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "May 10, 2023",
              intro: "E-commerce has reshaped the business landscape, offering vast opportunities for growth. This article delves into six key ways e-commerce websites can propel business expansion and revenue growth."
            },
            {
              section: "Expanded Reach",
              content: "E-commerce websites allow businesses to transcend geographical boundaries, reaching customers globally and significantly expanding their customer base and revenue potential."
            },
            {
              section: "Increased Sales",
              content: "By operating 24/7, e-commerce websites enable businesses to boost sales, offering customers the convenience to shop anytime, anywhere, with a user-friendly purchase process."
            },
            {
              section: "Cost-Effective",
              content: "Setting up an e-commerce platform is more cost-effective than a physical store, saving on rent and utilities while leveraging affordable digital marketing strategies to reach customers."
            },
            {
              section: "Improved Customer Experience",
              content: "E-commerce sites enhance the shopping experience with convenience, personalized recommendations, and a seamless purchase process, fostering customer loyalty and repeat sales."
            },
            {
              section: "Better Data and Analytics",
              content: "E-commerce platforms provide valuable insights into customer behavior and preferences, enabling data-driven decisions to optimize product offerings, pricing, and marketing strategies."
            },
            {
              section: "Scalability",
              content: "E-commerce websites offer scalability to manage increased traffic and inventory as businesses grow, accommodating expansion without significant changes to infrastructure."
            },
            {
              conclusion: "E-commerce websites are pivotal for business growth, offering expanded reach, increased sales, cost-effectiveness, enhanced customer experience, insightful data analytics, and scalability. They are essential for businesses aiming to excel in the digital marketplace."
            }
          ]      
        },
        {
          title: "The Benefits of Workflow Automation for Business Growth",
          summary:
            "Workflow automation has emerged as a key tool for businesses seeking to streamline operations, improve efficiency, and reduce costs. By automating repetitive tasks, companies can focus on growth and innovation.",
          author: "Veda Vyas Harikumar",
          readTime: "2 min read",
          
          link: "/blog/the-benefits-of-workflow-automation-for-business-growth",
          details:
          [
            {
              title: "The Benefits of Workflow Automation for Business Growth",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "April 10, 2023",
              intro: "Workflow automation has become essential for streamlining operations, enhancing efficiency, and reducing costs. This article outlines how automation of repetitive tasks can catalyze business growth and innovation."
            },
            {
              section: "Improved Efficiency",
              content: "Workflow automation accelerates routine tasks like data entry and document processing, freeing up employees to focus on higher-value tasks. This leads to increased productivity, efficiency, and a more competitive business."
            },
            {
              section: "Reduced Errors",
              content: "Automating manual processes reduces errors, enhances accuracy, and ensures reliable data capture and processing. This improvement fosters better decision-making and business outcomes."
            },
            {
              section: "Increased Collaboration",
              content: "By offering a centralized platform for information sharing, workflow automation enhances collaboration within teams and between departments, improving communication and teamwork."
            },
            {
              section: "Scalability",
              content: "Workflow automation supports business growth by enabling operations to scale without additional staffing, allowing businesses to handle increased workloads efficiently."
            },
            {
              section: "Better Customer Service",
              content: "Automating routine customer service tasks leads to faster response times and more personalized interactions, freeing staff to focus on complex requests and enhancing customer satisfaction."
            },
            {
              conclusion: "Workflow automation is a transformative tool for business growth in today's fast-paced environment. It streamlines operations, cuts costs, boosts efficiency, and improves collaboration, enabling strategic focus on growth and success."
            }
          ]      
        },
        {
          title: "How Artificial Intelligence can Help Businesses Grow",
          summary:
            "Artificial Intelligence (AI) is transforming the way businesses operate and make decisions. By using machine learning algorithms and deep neural networks, AI provides insights and efficiencies previously unattainable.",
          author: "Veda Vyas Harikumar",
          readTime: "2 min read",
          
          link: "/blog/how-artificial-intelligence-can-help-businesses-grow",
          details:
          [
            {
              title: "How Artificial Intelligence Can Help Businesses Grow",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "March 25, 2023",
              intro: "Artificial Intelligence (AI) is revolutionizing business operations and decision-making. With its capability to analyze data, automate processes, and understand customer behavior, AI is a key driver for efficiency, better decision-making, and revenue growth."
            },
            {
              section: "Data Analysis and Insights",
              content: "AI algorithms offer quick and accurate analysis of vast data, providing valuable insights into customer behavior, market trends, and operational efficiency, enabling data-driven business decisions."
            },
            {
              section: "Automation",
              content: "AI enhances productivity by automating repetitive tasks such as data entry and customer service. This automation not only reduces errors but also allows reallocation of resources to critical tasks."
            },
            {
              section: "Personalization",
              content: "AI enables businesses to tailor their products and services to individual customers, increasing customer loyalty and driving higher sales."
            },
            {
              section: "Fraud Detection",
              content: "AI algorithms are efficient in detecting fraudulent activities in financial transactions, significantly reducing the risk and financial losses associated with fraud."
            },
            {
              section: "Predictive Maintenance",
              content: "Utilizing AI to analyze sensor data from machinery predicts maintenance needs, thereby minimizing downtime and maintenance costs."
            },
            {
              section: "Chatbots and Virtual Assistants",
              content: "AI-powered chatbots and virtual assistants enhance customer service, providing around-the-clock support and efficiently handling common queries."
            },
            {
              conclusion: "AI is instrumental in business growth, offering insights, automation, personalization, fraud detection, predictive maintenance, and enhanced customer service. As AI evolves, its strategic application becomes increasingly vital for competitive edge and growth."
            }
          ]      
        },
        {
          title: "We Build Customized Solution for Building Successful Companies",
          summary:
            "Customized software has become a key tool for businesses in a variety of industries. By creating software tailored to specific needs, businesses can streamline operations and enhance productivity.",
          author: "Veda Vyas Harikumar",
          readTime: "2 min read",
          
          link: "/blog/we-build-customized-solution-for-building-successful-companies",
          details:
      [
            {
              title: "We Build Customized Solution for Building Successful Companies",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "March 20, 2023",
              intro: "Customized software is increasingly crucial across industries, offering tailored solutions to streamline processes, reduce costs, and enhance competitiveness."
            },
            {
              section: "Customization to Meet Specific Needs",
              content: "Customized software is developed to align with specific organizational needs, providing solutions unavailable in off-the-shelf software, thereby increasing efficiency, productivity, and cost savings."
            },
            {
              section: "Ease of Updates and Maintenance",
              content: "Since customized software is tailored for specific businesses, updates and maintenance are more straightforward, requiring less testing and reconfiguration."
            },
            {
              section: "High Degree of Flexibility",
              content: "Customized software offers adaptability to evolving business needs and forms a foundation for future growth and development."
            },
            {
              section: "Importance of Experienced Development Partners",
              content: "Selecting a reliable and skilled software development company is crucial for creating high-quality, user-specific software. Involving the user in the development process ensures the software meets their requirements."
            },
            {
              section: "Testing as a Key Component",
              content: "Extensive testing at various development stages is vital to ensure the software’s reliability and alignment with user requirements."
            },
            {
              conclusion: "Customized software is a key differentiator for businesses, offering streamlined processes, cost reduction, and enhanced efficiency. Collaborating with experienced developers and thorough testing are crucial for creating effective, tailored software solutions."
            }
          ]      
        },
        {
          title:
            "Process automation helps firms reduce typical manual work while boosting output and overall effectiveness",
          summary:
            "Workflow automation is the process of automating workflow processes using computer software. It has become a crucial part of business operations in recent times, enhancing efficiency and output.",
          author: "Veda Vyas Harikumar",
          readTime: "1 min read",
          
          link: "/blog/process-automation-helps-firms-reduce-typical-manual-work-while-boosting-output-and-overall-effectiveness",
          details:
     [
            {
              title: "Process Automation Helps Firms Reduce Typical Manual Work While Boosting Output and Overall Effectiveness",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "March 15, 2023",
              intro: "Workflow automation, leveraging computer software, is vital for modern business operations. It enhances productivity by integrating technologies like cloud computing and mobile phones."
            },
            {
              section: "Streamlining Processes",
              content: "Workflow automation fosters an efficient work environment by standardizing tasks within departments. This leads to automation of repetitive tasks, enhancing efficiency and reducing the need for close supervision."
            },
            {
              section: "Automated Processes for Interdepartmental Communication",
              content: "Businesses utilize workflow automation to create automated email templates, facilitating better communication within the company and resulting in increased efficiency across departments."
            },
            {
              section: "Managing Customer Contact Information Effectively",
              content: "Workflow automation enables efficient management of customer information through websites or CRM systems, significantly saving time and resources in customer service efforts."
            }
          ]
          
        },
        {
          title: "Digital Transformation",
          summary:
            "With the move to digital transformation, companies are now facing the challenge of replacing their manual processes with automated ones. This shift is driving a new era of efficiency and productivity.",
          author: "Veda Vyas Harikumar",
          readTime: "1 min read",
          
          link: "/blog/digital-transformation",
          details:
        [
            {
              title: "Digital Transformation",
              author: "Veda Vyas Harikumar",
              profile: "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
              publicationDate: "March 11, 2023",
              intro: "Digital transformation is reshaping how companies operate, moving from manual to automated processes. This shift brings cost savings, efficiency improvements, and enhanced customer experiences."
            },
            {
              section: "The Essence of Digital Transformation",
              content: "Digital transformation involves transitioning from manual to automated processes. It's a strategic shift that improves operational efficiency and customer experience by integrating digital tools into business operations."
            },
            {
              section: "Implementing Change Management Strategy",
              content: "Successful digital transformation requires a robust change management strategy, ensuring all stakeholders are aligned with the change. It's vital to provide adequate training and support to employees for effective utilization of new technologies and adaptation of their work practices."
            },
            {
              section: "Ensuring Seamless Transition",
              content: "The transition from manual to digital processes must be smooth and without disruption. Companies need to implement measures to ensure a seamless switch, safeguarding operational continuity and maximizing the benefits of digital transformation."
            }
          ]      
        },
      ];
    
      const location =useLocation();
      const blogId=location.pathname.split("/")[2];
     
      const blog = blogData.find(b => b.link === `/blog/${blogId}`);
    
  return (
    <div className=" w-full relative  h-full flex justify-center items-center ">
      <motion.div
        initial={{ top: 160 }}
        transition={{ duration: 0.3 }}
        animate={{ top: 0 }}
        className="   flex justify-center      text-white   w-full   lg:pt-5 pb-20 top-0 "
      >
        <div className=" max-w-screen-xl ">
          <div>
            <div className=" text-4xl font-bold   lg:mt-7 text-center">
              <motion.h1 className="  lg:min-w-[600px]  lg:text-center text-[28px]  lg:text-5xl   py-4 px-1  lg:px-2">
                {
                    blog.title
                }
              </motion.h1>
            </div>
       <div className=" text-base font-light  mx-auto   px-3  lg:px-20  mt-3  text-justify lg:text-center">
{blog.summary}
            </div>  
          </div>
        </div>
      </motion.div>
    </div>
  );
}
