// import { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import Footer from "./Footer";
// import Header from "./Header";
// import { useLocation } from "react-router-dom";
// import wave from "../assets/wave.svg";
// import footer_white from "../assets/footer_white.svg";
// import ContactUsCard from "../components/layout/ContactUsCard";
// import video2 from "../components/home/assets/Tech Motion Graphics_14-12-2023.mp4";
// import homeHeadlineImage2 from "../components/home/assets/homeHeaderImage2.jpeg";
// // import image1 from "../components/home/assets/homeHeadingImage.jpg";
// import image1 from "../components/home/assets/homeHeadingImage.jpg";

// export default function Layout({ children }) {
//   const location = useLocation();
//   const variants = {
//     about: {
//       top: "-150px",

//       transition: { duration: 0.3 },
//     },
//     blog: {
//       top: "-150px",

//       transition: { duration: 0.5 },
//     },
//     contact: {
//       top: "-574.5px",

//       transition: { duration: 0.5 },
//     },

//     default: {
//       top: "0px",

//       transition: { duration: 0.5 },
//     },
//     services: {
//       top: "-150px",

//       transition: { duration: 0.3 },
//     },
//   };
//   const [animationStyle, setAnimationStyle] = useState("Default");
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     if (location.pathname === "/") {
//       setAnimationStyle("default");
//     }
//     if (location.pathname === "/about") {
//       setAnimationStyle("about");
//     }
//     if (location.pathname === "/blog") {
//       setAnimationStyle("blog");
//     }
//     if (location.pathname === "/services") {
//       setAnimationStyle("services");
//     }
//     if (location.pathname === "/contact") {
//       setAnimationStyle("services");
//     }
//   }, [location]);

//   return (
//     <>
//       <motion.div
//         className={`absolute left-0 -z-10 w-full flex justify-center items-center`}
//         animate={variants[animationStyle]}
//         initial={{ top: -850 }}
//         transition={{ duration: 1 }}
//       >
//         {animationStyle === "default" ? (
//           <>
//             <div
//               style={{
//                 backgroundImage: `url(${image1})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//               }}
//               className={` w-[2000px]     lg:block hidden    relative  ${
//                 animationStyle === "services" ? " h-[654px] " : " h-[654px] "
//               }`}
//             >
//               <div className=" w-full h-full absolute  bg-[#0f143893]"></div>
//               <div
//                 className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
//                 style={{
//                   backgroundImage: `url(${footer_white})`,
//                   backgroundRepeat: "no-repeat",
//                   backgroundSize: "cover",
//                 }}
//               ></div>
//             </div>
//             <div
//          style={{
//             backgroundImage: `url(${image1})`,
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "cover",
//             backgroundPosition: "-300px -100px",
       
         
//          }}
//          className={` w-[2000px]   block lg:hidden     relative  ${
//            animationStyle === "services" ? " h-[654px] " : " h-[654px] "
//          }`}
//        >
//         <div className=" w-full h-full absolute  bg-[#0f143893]"></div>
//          <div
//            className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
//            style={{
//              backgroundImage: `url(${footer_white})`,
//              backgroundRepeat: "no-repeat",
//              backgroundSize: "cover",
//            }}
//          ></div>
//        </div>
//           </>
//         ) : (
//           <div
//             className={` w-full  lg:w-[2000px]   bg-[#0f1438]   relative  ${
//               animationStyle === "services" ? " h-[654px] " : " h-[654px] "
//             }`}
//           >
//             <div
//               className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
//               style={{
//                 backgroundImage: `url(${footer_white})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundSize: "cover",
//               }}
//             ></div>
//           </div>
//         )}
//       </motion.div>

//       <div>
//         <Header />
//         <div className="   ">{children}</div>

//         <Footer />
//       </div>
//     </>
//   );
// }


import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import wave from "../assets/wave.svg";
import footer_white from "../assets/footer_white.svg";
import ContactUsCard from "../components/layout/ContactUsCard";
import video2 from "../components/home/assets/Tech Motion Graphics_14-12-2023.mp4";
import homeHeadlineImage2 from "../components/home/assets/homeHeaderImage2.jpeg";
// import image1 from "../components/home/assets/homeHeadingImage.jpg";
import image1 from "../components/home/assets/HomeVector1.jpg";


export default function Layout({ children }) {
  const location = useLocation();
  const variants = {
    about: {
      top: "-150px",

      transition: { duration: 0.3 },
    },
    blog: {
      top: "-150px",

      transition: { duration: 0.5 },
    },
    contact: {
      top: "-574.5px",

      transition: { duration: 0.5 },
    },

    default: {
      top: "0px",

      transition: { duration: 0.5 },
    },
    services: {
      top: "-150px",

      transition: { duration: 0.3 },
    },
  };
  const [animationStyle, setAnimationStyle] = useState("Default");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === "/") {
      setAnimationStyle("default");
    }
    if (location.pathname === "/about") {
      setAnimationStyle("about");
    }
    if (location.pathname === "/blog") {
      setAnimationStyle("blog");
    }
    if (location.pathname === "/services") {
      setAnimationStyle("services");
    }
    if (location.pathname === "/contact") {
      setAnimationStyle("services");
    }
  }, [location]);

  return (
    <>
      <motion.div
        className={`absolute left-0 -z-10 w-full flex justify-center items-center`}
        animate={variants[animationStyle]}
        initial={{ top: -850 }}
        transition={{ duration: 1 }}
      >
        {animationStyle === "default" ? (
          <>
            <div
              style={{
                backgroundImage: `url(${image1})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "100px -50px",

              }}
              className={` w-full     lg:block hidden    relative  ${
                animationStyle === "services" ? " h-[654px] " : " h-[654px] "
              }`}
            >
              <div className=" w-full h-full absolute  -z-10 bg-[#000016]"></div>
              <div
                className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
                style={{
                  backgroundImage: `url(${footer_white})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div
         style={{
            backgroundImage: `url(${image1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "-300px -100px",   
         }}
         className={` w-[2000px]   block lg:hidden z-40     relative  ${
           animationStyle === "services" ? " h-[654px] " : " h-[654px] "
         }`}
       >
        <div className=" w-full h-full absolute  bg-[#101338]"></div>
         <div
           className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
           style={{
             backgroundImage: `url(${footer_white})`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "cover",
           }}
         ></div>
       </div>
          </>
        ) : (
          <div
            className={` w-full  lg:w-[2000px]   bg-[#0f1438]   relative  ${
              animationStyle === "services" ? " h-[654px] " : " h-[654px] "
            }`}
          >
            <div
              className={`absolute h-[300px] -bottom-[140px]  rotate-180 left-0 right-0   `}
              style={{
                backgroundImage: `url(${footer_white})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        )}
      </motion.div>

      <div>
        <Header />
        <div className="   ">{children}</div>

        <Footer />
      </div>
    </>
  );
}
