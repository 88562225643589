import { motion } from "framer-motion";
 

export default function ContactHeading() {
  // Variants for the text animations
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  // Container variants to stagger the children animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
  <>
  <div className=" w-screen  flex justify-center items-center ">

  <div className="flex justify-between items-center w-full    lg:mt-14  max-w-screen-xl  ">
      <motion.div
        className="flex flex-col items-start  gap-3     w-full h-full"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="gap-2 flex flex-col w-full text-white font-bold text-[38px]  lg:text-5xl "
          variants={containerVariants}

        >  

          <motion.h1 variants={textVariants} className="  lg:min-w-[600px] text-center  py-4 px-2">Get in Touch with Us Today!  </motion.h1>
      
        </motion.div>
        <motion.div
          className="text-sm text-gray-100  w-full flex flex-col items-start"
          variants={containerVariants}
        >
           
        
         </motion.div>
        
      </motion.div>
     
    </div>
  </div>
  </>
  );
}