import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { useContextStorage } from "../utils/ContextStorage";
import { Drawer } from "antd";

const nav_menu = [
  { name: "Home", to: "/", id: 1 },
  { name: "About Us", to: "/about", id: 2 },
  { name: "Services", to: "/services", id: 3 },
  { name: "Blog", to: "/blog", id: 4 },
];

export default function Header() {
  const { scrollLength } = useContextStorage();
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const index = nav_menu.findIndex((item) => item.to === location.pathname);
    if (index === 4) return;
    setActiveIndex(index);
  }, [location]);

  const [hoverState, setHoverState] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const drawerList = (
    <div
      role="presentation"
      onClick={() => toggleMobileMenu(false)}
      onKeyDown={() => toggleMobileMenu(false)}
      className="  rounded-lg  space-y-2 "
    >
      {nav_menu.map(({ name, to, id }) => (
        <Link
          key={id}
          to={to}
          className="p-2   rounded-lg  w-full block text-xl"
        >
          {name}
        </Link>
      ))}
      <Link to={"/contact"} className="p-2   rounded-lg  w-full block text-xl">
        Contact Us
      </Link>
    </div>
  );
  return (
    <div
      className={`flex justify-center items-center backdrop-filter backdrop-blur-2xl   shadow-lg ${
        activeIndex === 0 && "bg-transparent"
      } bg-[#0f1438]   -top-[150px] z-[100]  ${
        scrollLength > 200
          ? "bg-white fixed  transform translate-y-[150px] transition-all duration-1000 text-gray-900"
          : "  text-white  "
      } shadow-md  w-full`}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className=" max-w-screen-xl w-full "
      >
        <div className="flex justify-between items-center w-full px-5 py-3">
          <div className="flex justify-start items-center">
            <Link
              to="/"
              className=" px-[10px] w-fit items-center justify-center flex rounded-md py-1"
            >
              <img
                width={200}
                src={`
                ${
                  scrollLength > 100
                    ? "https://zodiacmatctest.s3.amazonaws.com/logo3.png"
                    : " https://zodiacmatctest.s3.amazonaws.com/Untitled+design+(3).png "
                }

                `}
                alt="logo"
              />
            </Link>
          </div>
          <div className="hidden lg:flex  justify-center  space-x-10 items-center   ">
            {nav_menu.map(({ name, to, id }) => (
              <div
                key={id}
                className=" flex relative  justify-center px-4 items-center  "
              >
                <Link
                  to={to}
                  className={`      `}
                  onMouseEnter={() => {
                    if (id === 5) {
                      return;
                    }
                    setHoverState(id);
                  }}
                  onMouseLeave={() => setHoverState(false)}
                >
                  {name}
                </Link>

                {(hoverState === id || activeIndex === id - 1) && (
                  <motion.div
                    initial={{ width: "0%" }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 0.4 }}
                    className={`absolute   bottom-[-10px] left-0 h-[2px] bg-[#eee] ${
                      scrollLength > 100 ? "bg-gray-700 " : "  bg-white"
                    } `}
                  ></motion.div>
                )}
              </div>
            ))}
            <Link to={"/contact"} className=" ">
              <button
                className={`   px-6 py-[10px] rounded-[5px]  duration-400    ${
                  scrollLength > 200
                    ? "text-white hover:scale-105 duration-300   bg-[#ee3232] "
                    : "  hover:bg-white duration-300 hover:text-[#ee3232] bg-[#ee3232] text-white"
                }`}
              >
                Contact Us
              </button>
            </Link>
          </div>
          <div className="flex justify-end items-center lg:hidden">
            <motion.div
              initial={{ opacity: 0, right: 0 }}
              animate={{ opacity: 1, right: 0 }}
              transition={{ duration: 0.5 }}
              onClick={() => {
                toggleMobileMenu();
              }}
              className="focus:outline-none outline-none"
            >
              {!isMobileMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6  text-white"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke={
                    scrollLength > 100 ? "#000" : "rgba(255, 255, 255, 1)"
                  }
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke="#000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </motion.div>
            {isMobileMenuOpen && (
              <Drawer
                width={300}
                anchor="right"
                open={isMobileMenuOpen}
                onClose={() => toggleMobileMenu(false)}
              >
                {drawerList}
              </Drawer>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
