import BlogDetails from "../components/blog/BlogDetails";
import HeadlineBlogDetailsPage from "../components/blog/HeadlineBlogDetailsPage";
import HeadlineBlogPage from "../components/blog/HeadlineBlogPage";

import { useContextStorage } from "../utils/ContextStorage";
export default function BlogDetailsPage() {
  const { scrollLength } = useContextStorage();
  return (
    <>
      <div className={` w-screen   ${scrollLength > 200 && " pt-[80px]"} `}>
        <HeadlineBlogDetailsPage />
        <BlogDetails />
        <div className=" h-5"></div>

        <div className=" h-0  lg:h-20"></div>
      </div>
    </>
  );
}
