// NotFoundPage.js
import React from "react";
import { Link } from "react-router-dom";
import astronaut from "./astronaut.png"; // Import the astronaut image

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#F4F2EE]">
      <div className="text-center">
        <p className="text-2xl font-semibold mb-8">
          The page you are looking for is not found
        </p>
        <img src={astronaut} alt="Lost in Space" className="mx-auto mb-8" />

        <Link
          to="/"
          className="inline-block px-8 py-2 text-sm font-semibold text-white bg-gradient-to-b from-[#C53B3B] to-[#d54141] rounded hover:bg-red-600"
        >
            Go Back to Home 
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
