import React from 'react';
import Validation from './Validation';

const LabelInputSmall = ({
  label,
  type,
  placeholder,
  value,
  error,
  onChange,
  width,
  widthFull,
  mandatory,
  disabled,
  readOnly,
  ...other
}) => {
  
  return (
    <div className={`flex flex-col gap-1 ${width==="full" ? " w-full px-5  max-w-[342px] md:max-w-[659px]  lg:max-w-[659px] "  : " w-[300px]  "} `}>
      <div className={` ${width==="full" ? " w-full "  : " w-[300px]  "}   h-full flex justify-start`}>
        <label
          className="text-[#344054]  text-sm font-[500]"
          htmlFor={label}
        >
          {label} {mandatory && <span className="text-[#D92D20]">*</span>}
        </label>
      </div>
      <div>
        <input
          type={type}
          name={label}
          id={label}
          className={`border ${error ? "border-[#F04438]" : "border-inputBorderGray"} text-sm text-textBlack  placeholder-textGray  outline-none font-normal rounded-lg pl-2 py-[7px]  ${width==="full" ? " w-full "  : " w-[300px]  "}  ${disabled && "bg-[#EEEEEE]"} ${readOnly ? "cursor-default" : "focus:border-[#1849A9]"}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          {...other}
        />
        <Validation>
          {error}
        </Validation>
      </div>
    </div>
  );
};

export default LabelInputSmall;
