import { motion } from "framer-motion";
import backgroundForContactUs from "./abstract-geometric-background-plexus-mesh-backdrop_250841-1411.jpg";
import { useNavigate } from "react-router-dom";

export default function ContactUsCard() {
  const navigate=useNavigate()
  return (
    <div className=" flex flex-col justify-center gap-16  items-center    lg:px-0   w-screen   h-auto lg:h-[420px] ">
      <div className=" flex flex-col justify-center gap-16   items-center   w-full  lg:w-[1000px]   h-auto lg:h-[420px] ">
        <div
          style={{
            backgroundImage: `url(${backgroundForContactUs})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className=" w-full h-full hover:-translate-y-2  py-10 lg:py-0  z-20  duration-1000  relative flex-col shadow-lg  justify-center rounded-none   lg:rounded-xl flex mt-5  items-center"
        >
          <div className=" z-40 flex-col  gap-10 flex     h-full rounded-none  lg:rounded-xl   justify-center items-center w-full">
            <div className="flex flex-col justify-center items-center w-full">
              <motion.h1
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="   text-[28px] px-5    lg:text-[40px] max-w-[800px] text-center    font-semibold text-[#fff]  relative "
              >
                Elevate{" "}
                <span className=" bg-[#E6293A] rounded-xl px-2">
                  Your Business
                </span>{" "}
                to Excellence by Turning Challenges into
                <span className="  pl-2">Opportunities.</span>
              </motion.h1>
            </div>{" "}
            <div className="flex justify-center items-center w-full">
              <button
              onClick={()=>navigate("/contact")}  
              className="hover:bg-[#E6293A] bg-white z-20 font-extralight text-[20px]  lg:text-[25px]   h-[45px] lg:h-14  text-center   hover:text-white  text-[#E6293A] flex items-center     transition-all ease-in-out duration-300   gap-3 justify-between    px-4     r rounded-xl">
                Talk to our expert Now
              </button>
            </div>
          </div>
          <div className=" w-full flex h-full   absolute top-0 left-0   opacity-60 rounded-none  lg:rounded-xl   bg-[#0f1438]   -z-10   flex-wrap   justify-center items-center">
            <div className="flex flex-col justify-center items-center w-full">
              <motion.h1
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="text-[20px] font-semibold text-gray-100  relative "
              ></motion.h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
