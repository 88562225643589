import React, { useState, useEffect, useRef } from 'react';
import "./index.css";

export default function Perks() {
  const [items, setItems] = useState([
    {
      title: "Enhancement of control effectiveness and the implementation of proactive control monitoring.",
      percentage: 75,
      currentCount: 0,
    },
    {
      title: "Decrease in the time required to oversee compliance and governance initiatives.",
      percentage: 90,
      currentCount: 0,
    },
    {
      title: "Improvement in employee involvement and commitment.",
      percentage: 65,
      currentCount: 0,
    },
    {
      title: "Reduced time dedicated to manual duties.",
      percentage: 60,
      currentCount: 0,
    },
  ]);

  // Create refs for each item
  const itemRefs = useRef(items.map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            const intervalId = setInterval(() => {
              setItems((currentItems) => {
                const newItems = [...currentItems];
                if (newItems[index].currentCount < newItems[index].percentage) {
                  newItems[index].currentCount += 1;
                } else {
                  clearInterval(intervalId);
                }
                return newItems;
              });
            }, 50); // Slower counting speed
            observer.unobserve(entry.target); // Stop observing after starting the animation
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the item is visible
    );

    itemRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [items.length]);

  return (
    <>
      <section className="perks-benefits mt-32" id="proactive">
        <h3 className=' relative'>
            Perks & Benefits of Digital Transformation
            <div className="  absolute -bottom-1 left-0 w-full  flex justify-center items-center ">
                  <div className=" w-[34.5%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
                </div>
            </h3>
        <div className="w-full px-10 flex justify-center items-center">
          <div className="flex flex-wrap lg:flex-nowrap gap-10 w-full max-w-screen-xl">
            {items.map((item, index) => (
              <div className="w-full" ref={itemRefs.current[index]} key={index}>
                <div className="box">
                  <div className="count">
                    <p><span>{item.currentCount}%</span></p>
                  </div>
                  <p className="text-center  text-base lg:text-[18px]">{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}