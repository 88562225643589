import { motion } from "framer-motion";
import erp from "./assets/portfolio/erp.png";
import crm from "./assets/portfolio/crm.png";
import uiux from "./assets/portfolio/uiux.png";
import hrms from "./assets/portfolio/hrms.png";
import ecommerce from "./assets/portfolio/ecommerce.png";
import mobile from "./assets/portfolio/mobile.png";
import web from "./assets/portfolio/web.png";
import microsoft from "./assets/portfolio/microsoft.png";
import cloudSupport from "./assets/portfolio/cloudSupport.png";
import iot from "./assets/portfolio/iot.png";
import school from "./assets/portfolio/school.png";
import hospital from "./assets/portfolio/hospital.png";
import firewall from "./assets/portfolio/firewall.png";
import network from "./assets/portfolio/network.png";
import cyber from "./assets/portfolio/cyber.png";
import cloudBilling from "./assets/portfolio/cloudBilling.png";
import { useState } from "react";
import PortfolioCard from "./PortfolioCard";

export default function ServicePortfolio() {
  const [hovered, setHovered] = useState(-1);
  const content = [
    {
      image: erp,
      title: "ERP Solution",
      description:
        "Our ERP solution streamlines your business operations, enhancing efficiency and productivity. With seamless integration and powerful tools, it empowers you to manage resources and data with ease, driving growth and success.",
    },
    {
      image: crm,
      title: "CRM Solution",
      description:
        "Discover the power of our CRM solution, designed to streamline your customer relationship management with precision. Seamlessly manage interactions, boost productivity, and nurture lasting customer connections with our user-friendly CRM software.",
    },
    {
      image: uiux,
      title: "UI & UX Design",
      description:
        "Crafting exceptional user experiences is at the heart of our UI and UX design services. We create stunning and functional designs that captivate and delight your audience.",
    },
    {
      image: hrms,
      title: "HRMS - Human Resource Management Solution",
      description:
        "Our Human Resource Management Solution is designed to streamline your HR processes, from payroll and employee data management to performance evaluations and beyond. Simplify HR tasks, enhance productivity, and focus on what matters most - your people.",
    },
    {
      image: ecommerce,
      title: "E-commerce Solution",
      description:
        "Discover our tailored E-commerce solutions, designed to elevate your online business. From seamless user experiences to robust payment processing, we provide everything you need for e-commerce success.",
    },
    {
      image: mobile,
      title: "Mobile App (Android & iOS)",
      description:
        "Experience seamless access and functionality on the go with our mobile app, available on both Android and iOS platforms. Stay connected, manage tasks, and enjoy a user-friendly interface for a more convenient and efficient mobile experience.",
    },
    {
      image: web,
      title: "Website Design & Development",
      description:
        "Transform your digital presence with our expert website design and development services. We create stunning, user-friendly websites tailored to your brand's unique needs, ensuring a strong online presence and enhanced user experiences.",
    },
    {
      image: microsoft,
      title: "Microsoft Dynamic 365",
      description:
        "Unlock the potential of your business through our customized MS Dynamics 365 services, bolstering your Sales, Marketing, and Customer Integration capabilities.",
    },
    {
      image: cloudBilling,
      title: "Cloud Billing Software",
      description:
        "Streamline your financial operations and gain better control over your cloud expenses with our Cloud Billing Software. Our solution simplifies and enables businesses to manage their cloud services cost-effectively.",
    },
    {
      image: iot,
      title: "IoT/AI Application",
      description:
        "Discover the limitless possibilities of IoT and AI applications with us. Transform your business operations with cutting-edge technology to unlock efficiency, insights, and innovation.",
    },
    {
      image: school,
      title: "School Management Solution",
      description:
        "Experience seamless school administration with our School Management Solution. Streamline all aspects of your educational institution, from student records to attendance tracking, in one user-friendly platform.",
    },
    {
      image: hospital,
      title: "Hospital Management Solution",
      description:
        "Experience seamlessly integrates administrative, clinical, and financial processes, streamlining operations and enhancing patient care. With our user-friendly software, healthcare facilities can optimize efficiency and provide top-notch services, ensuring the best possible patient experience.",
    },
    {
      image: firewall,
      title: "Firewall Solution",
      description:
        "Secure your digital infrastructure with our cutting-edge Firewall Solution. Our robust firewall technology provides comprehensive protection, safeguarding your data and network from threats and intrusions, ensuring peace of mind for your business.",
    },
    {
      image: network,
      title: "Learning Management System",
      description:
        "Streamline your educational and training processes with our advanced Learning Management System (LMS). This centralized platform efficiently manages, delivers, and tracks your learning content, enhancing the effectiveness of your educational programs.",
    },
    {
      image: cyber,
      title: "Cyber Security",
      description:
        "Enhance your cybersecurity posture with our expert solutions for seamless connectivity. We guarantee the efficiency and security of your network, customized to meet your specific requirements.",
    },
    {
      image: cloudSupport,
      title: "Cloud Support",
      description:
        "Experience seamless cloud support that ensures your business's digital infrastructure remains robust and reliable. Our expert team is here to provide round-the-clock assistance, so you can focus on what matters most - growing your business.",
    },
  ];

  return (
    <>
      <div className=" flex flex-col justify-center gap-2  mt-20 lg:mt-32 items-center   w-full h-full ">
        <div className=" flex justify-center items-center w-full">
          <div className="flex flex-col justify-center items-center w-full">
            <motion.h1
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4 }}
              className="text-3xl  relative   heading"
            >
              Our Service Portfolio
              <div className="  absolute  -bottom-2 left-0 w-full  flex justify-center items-center ">
                <div className=" w-[100%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
              </div>
            </motion.h1>
          </div>
        </div>
        <div className=" w-full flex  gap-5  lg:gap-12 space-y-5     flex-wrap max-w-[1350px] justify-center items-center">
          {content.map((item, index) => (
            <div
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(-1)}
              key={index}
            >
              <PortfolioCard
                item={item}
                index={index}
                isHovered={hovered === index}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
