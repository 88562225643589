import { motion } from "framer-motion";
export default function HeadlineServicePage() {
  return (
    <div className=" w-full relative  h-full flex justify-center items-center ">
      <motion.div
        initial={{ top: 160,   }}
        transition={{ duration: 0.3 }}
        animate={{ top: 0,  }}
        className="   flex justify-center      text-white   w-full  lg:pt-5  lg:pb-20 top-0 "
      >
        <div className=" max-w-screen-xl ">
          <div>
            <div className=" text-4xl font-bold   lg:mt-7 text-center">
      
              <motion.h1  className="  lg:min-w-[600px] text-center text-[28px]  lg:text-5xl    py-4 px-2">  Welcome to   <span className="text-[#E6293A]">   Toyo Digital Innovation</span>   Services  </motion.h1>

            </div>
            <div className=" text-sm font-light  mx-auto    px-3  lg:px-20   mt-5 text-justify lg:text-center">
              At Toyo Digital Innovation, we pride ourselves on delivering
              cutting-edge services that empower businesses to thrive in an
              ever-evolving digital landscape. Our comprehensive suite of
              services is designed to meet your unique needs and drive
              sustainable growth. With our expertise and commitment to
              innovation, we ensure your business is well-equipped to succeed in
              today's competitive market.
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
