import React from "react";
import "./styles/ServiceCard.css";
function ServiceCard({ image, title, description, buttonText }) {
  return (
    <div
      className="card    h-[300px]   rounded-[3px]    "
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={` w-full h-full p-[1rem]     hover:pt-10 bg-gray-900/70  ${title=== "Experienced Team" ? " pt-[90px]" : " pt-28"} `}>

      <div className="content   ">
        <h2 className="title">{title}</h2>
        <p className="copy">{description}</p>
      </div>
      </div>
    </div>
  );
}

export default ServiceCard;
