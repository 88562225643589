import { motion } from 'framer-motion';
import React from 'react';
 import vision from './assets/vision.png';
  import mission from './assets/mission.png';
  import values from './assets/values.png';

export default function VisionMission() {
  const data = [
    {
      id: '01',
      title: 'Vision',
      text: 'Toyo Digital Innovation aspires to be at the forefront of technological evolution, shaping a dynamic and sustainable future. Our vision is to harness innovation, creativity, and cutting-edge digital solutions to empower individuals and organizations, enabling them to thrive in a rapidly changing world. We aim to be the trusted partner in the digital realm, redefining possibilities and setting new standards for excellence.      ',
      icon: vision
    },
    {
      id: '02',
      title: 'Mission',
      text: 'Our mission is to lead in digital innovation, equipping individuals and organizations for success in a changing landscape. We anticipate needs, deliver lasting solutions, and focus on empowering stakeholders with future-ready skills and technologies. Committed to societal impact, we aim to foster community progress and resilience through digital empowerment.  ',
      icon:  mission
    },
    {
      id: '03',
      title: 'Values',
      text: 'At Toyo Digital Innovation, we thrive on innovation, uphold unwavering integrity, foster collaboration, pursue excellence, prioritize our customers, and adapt seamlessly to change. These values guide us in delivering cutting-edge solutions and services, ensuring our clients  success in an ever-evolving digital landscape ',
      icon:     values
    }
  ];

  const cardVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="flex justify-center items-center  mt-10  lg:mt-20 ">
      <motion.div
        className="flex flex-wrap justify-center gap-10  lg:gap-20"
        variants={cardVariants}
        initial="hidden"
        animate="visible"
      >
        {data.map((item, index) => (
          <motion.div
            key={index}
            className="bg-white shadow-xl flex  flex-col  items-center  rounded-lg  py-3  w-[340px]"
            variants={cardVariants}
          >
        
            <div className="flex w-40 justify-center items-center">
              <img src={item.icon} alt="icon"
                
              />
                </div>
            <h3 className="text-2xl font-semibold my-3  text-[#101338]">{item.title}</h3>
            <p className="text-gray-600 text-sm text-justify px-6 ">{item.text}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}
