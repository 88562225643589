import { motion } from "framer-motion";
import HexagonalCard from "./HexagonalCard";
import IsoCard from "./IsoCard";
export default function IsoManagement() {
  return (
    <div className=" flex flex-col justify-center gap-16 mt-16 items-center   ">
      <div className=" flex justify-center items-center w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="text-[45px]  heading font-semibold    relative "
          >
            ISO Management Solutions
            <div className="  absolute  -bottom-0 left-0 w-full  flex justify-center items-center ">
              <div className=" w-[100%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
            </div>  <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="text-[20px] absolute  mt-2 w-full text-center flex justify-center items-center  lg:-bottom-10 pl-2 font-semibold text-gray-700    "
          >
            Enhancing Efficiency, Quality and Compliance
          </motion.h1>
          </motion.h1>
        
        </div>
      </div>
      <div className=" w-full flex     flex-wrap max-w-[1350px] justify-center items-center">
        <IsoCard />
      </div>
    </div>
  );
}
