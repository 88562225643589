import { useEffect, useState, useRef } from "react";

export default function Dropdown({
  data,
  displayValue,
  returnValue,
  width,
  placeholder,
  selectedOption,
  setSelectedOption,
  error,
  label,
  mandatory,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (item) => {
    setSelectedOption(item);
    setIsDropdownOpen(false);
  };

  return (
    <div ref={dropdownRef} className={`relative ${width==="full" ? " w-full px-5  mb-2 max-w-[342px] md:max-w-[659px]  lg:max-w-[659px] "  : " w-[300px]  "} `} >
        <div className={` ${width==="full" ? " w-full "  : " w-[300px]  "} h-full flex justify-start`}>
        <label
          className="text-[#344054] pb-[2px]  text-sm font-[500]"
          htmlFor={label}
        >
          {label} {mandatory && <span className="text-[#D92D20]">*</span>}
        </label>
      </div>
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className={`text-center flex justify-around ${!selectedOption && error ? "border-[#F04438]" : "border-inputBorderGray"
          }  ${width==="full" ? " w-full "  : "w-[300px]  "} z-40 px-3 py-[8px] items-center mt-[1px] text-base text-[#4e4e4e] border-[1px]  appearance-none bg-white rounded-lg leading-tight focus:outline-none focus:bg-white`}
        type="button"
        onClick={toggleDropdown}
      >
        <div
          className={`flex w-full  text-sm font-normal ${selectedOption && selectedOption[displayValue] ? "text-[#101828]" : "text-placeholderTextGray"
            }`}
        >
          {(selectedOption && selectedOption[displayValue]) || placeholder}
        </div>
        <svg
          className={`w-2.5 ${isDropdownOpen && "transform rotate-180"}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="#6F7990"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isDropdownOpen && (
        <div
          id="dropdown"
          className={`absolute z-40 drop-shadow-xl  ${width==="full" ? " w-full "  : "w-[300px]  "} mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <ul className="py-2 text-sm text-gray-700">
            {data.map((item) => (
              <li key={item.id}>
                <button
                  href="#"
                  className={`block px-4 py-2 w-full text-left hover:bg-gray-100`}
                  onClick={() => handleOptionClick(item)}
                >
                  <p className="text-dropdownTextGray">
                    {item[displayValue]}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!selectedOption && error && (
        <div className=" absolute  text-[#F04438] font-normal text-[13px] text-left">
          {error}
        </div>
      )}
    </div>
  );
}
