import side_image_home from "../../assets/side_image_home.png";
import video from "./assets/Tech Motion Graphics_14-12-2023.mp4";

import { Link } from "react-router-dom";
import wave_for_heading from "./assets/wave_for_heading.svg";

export default function Explore_section() {
  return (
    <>
    
    <div className="flex justify-around  lg:mt-32  mt-[50px] gap-10 h-full max-w-screen-xl  items-center w-full">
      <div className="hidden lg:block relative  max-w-screen-xl  py-10 p-6   border-gray-200 bg-[#0e214600] rounded-lg  ">
        <div className=" flex w-full   items-center">

          <div className="flex  w-full   items-center justify-center   z-10 ">
            <div className=" w-full ">
              <h5 className="mb-2 text-4xl pb-5 font-bold tracking-tight w-[450px] text-[#101338]">
                Explore the Transformation of the Digital Age
              </h5>
              <div className="h-1 w-1/2 bg-[#E6293A]"></div>
              <p className="font-normal pt-5 text-gray-700 w-[400px] text-justify">
                In today's fast-paced world, the digital landscape is constantly
                evolving. Innovation is the driving force that's reshaping
                industries, businesses, and our very way of life. At Toyo
                Digital Innovation, we're at the forefront of this digital
                revolution, and we invite you to join us on a journey to explore
                the profound transformation that's taking place in the digital
                age.
              </p>
              <div className="px-4 py-1 hover:bg-[#ffffff] mt-5  border-[#E6293A] border w-fit rounded-md bg-[#E6293A] hover:text-[#E6293A] text-white">
                <Link to="/services" className="text-xl font-semibold">
                  Explore
                </Link>
              </div>
            </div>
            <div className=" hidden lg:flex justify-center   -z-10 items-center w-full">
              <video  src={video} className=" rounded-lg" muted loop autoPlay></video>
            </div>
          </div>
        </div>
      </div>
        {/* <div className=" absolute w-full flex justify-center items-center top-0 left-1">
        <img width="2000" height={1} src={wave_for_heading} alt="" />

        </div> */}
      <div className="block  lg:hidden      py-10  border-gray-200 bg-[#0e214600] rounded-lg  ">
        <div className=" flex w-full flex-col   items-center">
          <div className="flex  w-full   items-center justify-center   z-10 ">
            <div className=" w-full ">
              <h5 className="mb-2 pl-5 text-4xl pb-5 font-bold tracking-tight   text-[#101338]">
                Explore the Transformation of the Digital Age
              </h5>
              <div className="h-1   bg-[#E6293A]"></div>
              <div className="  flex justify-center   mt-10 items-center w-full">
                <video src={video} className="" muted loop autoPlay></video>
              </div>
              <p className="font-normal px-5 pt-5 text-gray-700   text-justify">
                In today's fast-paced world, the digital landscape is constantly
                evolving. Innovation is the driving force that's reshaping
                industries, businesses, and our very way of life. At Toyo
                Digital Innovation, we're at the forefront of this digital
                revolution, and we invite you to join us on a journey to explore
                the profound transformation that's taking place in the digital
                age.
              </p>
              <div className="px-5 ml-5   py-1 hover:bg-[#ffffff] mt-5  border-[#E6293A] border w-fit rounded-md bg-[#E6293A] hover:text-[#E6293A] text-white">
                <Link to="/services" className="text-xl font-semibold">
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
