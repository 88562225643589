import { motion } from "framer-motion";
import backgroundImage from "./assets/bgaboutuslogo.jpg";

export default function AboutToyo() {
  // Variants for the text animations
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  // Container variants to stagger the children animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <>
      <div className=" w-screen   flex justify-center items-center   mt-20 ">
        <div className="flex justify-center items-center w-full   relative  mt-10 ">
          {" "}
          <div className="absolute inset-0 z-0">
            <img
              src={backgroundImage}
              alt="Background"
              style={{
                  objectPosition: "100px -100px",
              }}
              className="w-full h-full   object-cover 
              

              opacity-50 bg-gray-900 "
            />
          </div>
          <motion.div
            className="flex flex-col    gap-3   bg-gray-900  items-center   w-full  h-full lg:h-[520px]"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="gap-2 flex flex-col w-full  max-w-screen-xl  text-white font-bold text-xl "
              variants={containerVariants}
            >
              <motion.h1
                variants={textVariants}
                className="   w-full relative   text-center z-30  leading-10  pt-10 lg:pt-24  pb-5 lg:pb-12 text-[#fff]  text-[35px] lg:text-[40px] py-4   px-2"
              >
                About Toyo Digital Innovation – Est -2023
                <div className="  absolute  bottom-2  lg:bottom-7 left-0 w-full  flex justify-center items-center ">
                  <div className=" w-[64.5%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
                </div>
              </motion.h1>
            </motion.div>
            <motion.div
              className="text-sm text-gray-100   flex justify-center items-center w-full  "
              variants={containerVariants}
            >
              <motion.h1
                className=" text-sm px-10  lg:text-[24px] leading-5 pb-10 lg:pb0  lg:leading-9 text-justify z-30 w-full    text-[#fff] lg:px-40"

                variants={textVariants}
              >
                Toyo Digital Innovation stands as a forefront technology-driven
                company specializing in enhancing business performance.
                Leveraging our proficiency across various sectors, we empower
                enterprises to expand their operations. Our primary focus lies
                in optimizing business aspects such as people, processes, and
                technology. In a rapidly evolving world, our persistent
                endeavour are shaping a future where industries experience
                revolutionary transformations, unlocking the boundless potential
                of digital technology.
              </motion.h1>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

// import { motion } from "framer-motion";
// import backgroundImage from "./assets/About T.png";

// export default function AboutToyo() {
//    const textVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.7,
//         ease: "easeInOut",
//       },
//     },
//   };

//   // Container variants to stagger the children animations
//   const containerVariants = {
//     hidden: {},
//     visible: {
//       transition: {
//         staggerChildren: 0.1,
//       },
//     },
//   };
//   return (
//     <div className="w-screen flex justify-center items-center mt-20">
//       <div className="flex justify-center items-center w-full mt-14 max-w-screen-xl">
//         {/* Left side with the girl image */}
//         <div className="hidden lg:block lg:w-1/2 h-[600px]">
//           <img
//             src={backgroundImage}
//             alt="backgroundImage"
//             className="w-full h-full object-cover"
//           />
//         </div>

//         {/* Right side with the content */}
//         <motion.div
//           className="flex flex-col items-center justify-center gap-3 w-full lg:w-1/2 h-[600px] p-10"
//           variants={containerVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           <motion.h1
//             variants={textVariants}
//             className="text-xl font-bold text-gray-800 py-4 px-2 text-center"
//           >
//             About Toyo Digital Innovation – Est -2023
//           </motion.h1>
//           <motion.p
//             className="text-sm text-gray-800 text-center"
//             variants={textVariants}
//           >
//             We founded by three visionary leaders from diverse industries,
//             has swiftly risen to prominence as a prominent player in the
//             dynamic field of digital technology. Fueled by an unwavering
//             commitment to pioneering innovation and industry transformation,
//             we are at the forefront of redefining what's possible with
//             advanced digital solutions. In a world continually expanding,
//             our relentless efforts are shaping a future where industries
//             undergo revolutionary change, and the limitless potential of
//             digital technology is fully realized.
//           </motion.p>
//         </motion.div>
//       </div>
//     </div>
//   );
// }

// import { motion } from "framer-motion";
// import backgroundImage from "./assets/About T.png";

// export default function AboutToyo() {
//   // Variants for the text animations
//   const textVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.7,
//         ease: "easeInOut",
//       },
//     },
//   };

//   // Container variants to stagger the children animations
//   const containerVariants = {
//     hidden: {},
//     visible: {
//       transition: {
//         staggerChildren: 0.1,
//       },
//     },
//   };

//   return (
//     <>
//       <div className=" w-screen   flex justify-center items-center   mt-20 ">

//         <div className="flex justify-center items-center w-full     bg-[#101338]  mt-14  max-w-screen-xl  ">
//           <motion.div
//             className="flex flex-col items-center justify-center  gap-3     w-full  "
//             variants={containerVariants}
//             initial="hidden"
//             animate="visible"
//           >
//             <motion.div
//               className="gap-2 flex flex-col w-full text-white font-bold text-xl "
//               variants={containerVariants}
//             >
//               <motion.h1
//                 variants={textVariants}
//                 className="  text-center z-30 text-[#fff] text-[42px] pt-10 pb-5 px-2"
//               >

//                 About Toyo Digital Innovation – Est -2023
//               </motion.h1>
//             </motion.div>
//             <motion.div
//               className="text-sm text-gray-100   flex justify-center items-center w-full  "
//               variants={containerVariants}
//             >
//               <motion.h1
//                 className=" text-[18px] leading-6   z-30 w-full  text-justify   text-[#fff] lg:px-20 pb-10"
//                 variants={textVariants}
//               >
//                 We founded by three visionary leaders from diverse industries,
//                 has swiftly risen to prominence as a prominent player in the
//                 dynamic field of digital technology. Fueled by an unwavering
//                 commitment to pioneering innovation and industry transformation,
//                 we are at the forefront of redefining what's possible with
//                 advanced digital solutions. In a world continually expanding,
//                 our relentless efforts are shaping a future where industries
//                 undergo revolutionary change, and the limitless potential of
//                 digital technology is fully realized.
//               </motion.h1>
//             </motion.div>
//           </motion.div>
//         </div>
//       </div>
//     </>
//   );
// }
