import { motion } from "framer-motion";
export default function HeadlineBlogPage() {
  return (
    <div className=" w-full relative  h-full flex justify-center items-center ">
      <motion.div
        initial={{ top: 160 }}
        transition={{ duration: 0.3 }}
        animate={{ top: 0 }}
        className="   flex justify-center      text-white   w-full  pb-10  lg:pb-20 top-0 "
      >
        <div className=" max-w-screen-xl ">
          <div>
            <div className="  text-4xl font-bold  mt-7 text-center">
              <motion.h1 className="  lg:min-w-[600px] text-center text-[28px]  lg:text-5xl  py-4  px-1  lg:px-2">
                {" "}
                Insights and Innovations:    
                <span className="text-[#E6293A]">
                  {" "}
                  Toyo Digital Innovation
                </span>{" "}
                Blog 
              </motion.h1>
            </div>
            {/* <div className=" text-sm font-light  mx-auto   px-20  mt-5 text-center">
              Explore the world of Toyo Digital Innovation Services, where
              technological innovation meets business acumen. Dive into our blog
              to uncover how our unique blend of services can empower your
              business to excel in the digital era. From bespoke digital
              strategies to advanced technological solutions, learn how Toyo
              Digital Innovation is revolutionizing the way businesses operate
              and compete in today's fast-paced, digital marketplace.
            </div> */}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
