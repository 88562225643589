import React from "react";
import Slider from "react-slick";

// Importing client images
import client1 from "./assets/clients/Approved_logo_for_eddie_page-0001-removebg-preview.png";
import client2 from "./assets/clients/Dala.png";
import client3 from "./assets/clients/VT-tela-4.png";
import client4 from "./assets/clients/admire-logo.webp";
import client5 from "./assets/clients/desertrain-logo.png";
import client6 from "./assets/clients/logo (1).png";
import client7 from "./assets/clients/logo.png";
import client8 from "./assets/clients/Dala1.png";
import client9 from "./assets/clients/desertrain.jpeg";
import client10 from "./assets/clients/dalatech.jpeg";
// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Clients() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientImages = [
    client1,
    client10,
    client3,
    client4,
    client9,
    client6,
    client7,
  ];

  return (

    <div className="w-screen my-20 mt-32">
      {
        <>
        <style jsx>{`
          
          .slick-arrow slick-next {
            display:none !important;
          }
        `}</style>

        </>
      }
      <div className=" text-center heading relative text-[45px] ">
        Clients & Partners We Serve
        <div className="  absolute  -bottom-1 left-0 w-full  flex justify-center items-center ">
          <div className=" w-[22%] bg-[#EE3231] h-[3px] rounded-2xl "></div>
        </div>
      </div>
      <Slider {...settings}>
        {clientImages.map((client, index) => (
          <div
            className=" py-10  flex justify-center items-center bg-white gap-2 w-40 h-40"
            key={index}
          >
            <img src={client} className=" w-40 " alt={`Client ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
