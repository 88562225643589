import { motion } from "framer-motion";
import video1 from "./assets/Tech Motion Graphics.mp4";
import video2 from "./assets/Tech Motion Graphics_14-12-2023.mp4";
import homeHeadlineImage1 from "./assets/homeHeaderImage1.jpeg";
import homeHeadlineImage2 from "./assets/homeHeaderImage2.jpeg";
import { useNavigate } from "react-router-dom";

export default function Headline() {
  // Variants for the text animations
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  // Container variants to stagger the children animations
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="flex  relative justify-between items-center w-full  z-50  mt-2  lg:mt-10  max-w-screen-xl  ">
        <motion.div
          className="flex flex-col items-start  gap-10   w-full h-full"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="gap-2 flex flex-col pl-2 lg:pl-4 w-full text-white font-bold text-5xl"
            variants={containerVariants}
          >
            <motion.h1
              variants={textVariants}
              className="  lg:min-w-[800px]  py-3  px-2  lg:px-0"
            >
              {" "}
              <span className="text-[#E6293A]">Transforming</span> Your World{" "}
            </motion.h1>
            <motion.h1
              className="  px-2  lg:px-0"
            variants={textVariants}>
           
              Through Digital{" "}
              <motion.span variants={textVariants} className="text-[#E6293A]">
                Excellence
              </motion.span>
            </motion.h1>
          </motion.div>
          <motion.div
            className="text-sm text-gray-100 pl-4 lg:pl-4   w-full flex flex-col items-start"
            variants={containerVariants}
          >
            <motion.h1 variants={textVariants}>
              Unlock your full potential of your Business with Toyo Digital
            </motion.h1>
            <motion.h1 variants={textVariants}>
              Innovation’s top-notch IT Consulting & Software
            </motion.h1>
            <motion.h1 variants={textVariants}>Development service</motion.h1>
          </motion.div>
          <motion.div
            variants={textVariants}
            initial="hidden"
            animate="visible"
            className="flex justify-start pl-4 lg:pl-4  items-center w-full"
          >
            <motion.button
              variants={textVariants}
              initial="hidden"
              animate="visible"
              onClick={() => navigate("/contact")}
              className="bg-[#E6293A] hover:bg-white z-20 font-extralight text-  h-[44px]  text-white  hover:text-[#E6293A] flex items-center   w-[210px] hover:w-[220px] transition-all ease-in-out duration-300   gap-3 justify-between    pl-4 rounded-tl-[30px] rounded-bl-[30px]      rounded-tr-[100px] rounded-br-[100px]"
            >
              Talk to our expert
              <div className=" rounded-full border-2 border-[#E6293A]  bg-white  flex justify-center items-center  w-[44px] h-[44px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="#000"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </div>
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}

// import { motion } from 'framer-motion';

// // Helper function to split text into letters for animation
// const AnimatedLetters = ({ text, variants }) => {
//   return text.split("").map((char, index) => (
//     <motion.span key={index} variants={variants}>
//       {char}
//     </motion.span>
//   ));
// };

// export default function Headline() {
//   // Letter animation variants
//   const letterVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: { opacity: 1, y: 0

//     }
//   };

//   // Word animation variants to stagger the letters
//   const wordVariants = {
//     hidden: {},
//     visible: {
//       transition: {
//         staggerChildren: 0.03, // Adjust the speed of "typing" here
//       }
//     }
//   };

//   return (
//     <div className="flex justify-between items-center w-full">
//       <div className="flex flex-col items-start justify-start gap-10 w-full h-full">
//         <motion.div
//           className="gap-2 flex flex-col w-full text-gray-900 font-bold text-5xl"
//           variants={wordVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           <h1>
//             <AnimatedLetters text="Transforming Your World" variants={letterVariants} />
//           </h1>
//           <h1>
//             <AnimatedLetters text="Through Digital" variants={letterVariants} />
//           </h1>
//           <h1>
//             <AnimatedLetters text="Excellence" variants={letterVariants} />
//           </h1>
//         </motion.div>
//         <motion.div
//           className="text-sm text-gray-900 font-bold w-full flex flex-col items-start"
//           variants={wordVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           <h1>
//             <AnimatedLetters text="Unlock your full potential of your Business with Toyo Digital" variants={letterVariants} />
//           </h1>
//           <h1>
//             <AnimatedLetters text="Innovation’s top-notch It Consulting & software" variants={letterVariants} />
//           </h1>
//           <h1>
//             <AnimatedLetters text="Development service" variants={letterVariants} />
//           </h1>
//         </motion.div>
//         <div className="flex justify-start items-center w-full">
//           <button className="bg-white text-red-400 py-2 px-4 rounded-md text-2xl">
//             Get Started
//           </button>
//         </div>
//       </div>
//       <div className="w-full items-center flex justify-center">
//         {/* Other content */}
//       </div>
//     </div>
//   );
// }
