import { Link, useLocation, useParams } from "react-router-dom";
import image8 from "./assets/blog card images/1678518381934 (1).jpeg";
import image7 from "./assets/blog card images/1678859367947 (1).jpeg";
import image6 from "./assets/blog card images/1679308341569 (1).jpeg";
import image5 from "./assets/blog card images/1679742374430 (1).jpeg";
import image4 from "./assets/blog card images/1681110061935 (1).jpeg";
import image3 from "./assets/blog card images/1683694749972 (1).jpeg";
import image2 from "./assets/blog card images/1688362746809 (1).jpeg";
import image1 from "./assets/blog card images/1695319212372 (1).jpeg";
import { Card } from "antd";
import BlogCards from "./BlogCards";

export default function BlogDetails() {
  const blogData = [
    {
      title:
        "Optimising Efficiency and Confidence: The Impact of Internal Audit Software",
      summary:
        "In the dynamic landscape of modern business, adaptation and innovation are the keys to survival and success. One pivotal element that has undergone a significant evolution is internal audit, with the introduction of sophisticated software tools.",
      author: "Veda Vyas Harikumar",
      readTime: "3 min read",
      image: image1,
      link: "/blog/optimising-efficiency-and-confidence-the-impact-of-internal-audit-software",
      details: [
        {
          title:
            "Optimising Efficiency and Confidence: The Impact of Internal Audit Software",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "September 21, 2023",
          intro:
            "In the dynamic landscape of modern business, adaptation and innovation are the keys to survival and success. One pivotal element that has undergone a remarkable transformation is the art of internal auditing, and at its core lies the technological marvel known as Internal Audit Software. This article explores how Internal Audit Software is revolutionizing the way organizations safeguard their assets, ensure compliance, and navigate the intricate web of risks in today's business world.",
        },
        {
          section: "The Evolution of Internal Auditing",
          content:
            "Not too long ago, internal audits were often associated with stacks of paperwork, endless spreadsheets, and painstaking manual processes. The traditional audit approach was not only time-consuming but also prone to human errors, making it challenging for organizations to stay compliant and effectively manage risks. However, the advent of Internal Audit Software has ushered in a new era of efficiency and accuracy.",
        },
        {
          section: "What Is Internal Audit Software?",
          content:
            "Internal Audit Software, often referred to as IAS, is a sophisticated tool designed to streamline and enhance the auditing process within organizations. It encompasses a range of digital solutions and platforms that enable auditors to plan, execute, and report on audits with precision and ease. These software applications are tailored to meet the specific needs of businesses, regardless of their size or industry. They offer a comprehensive suite of features, including automated data analysis, real-time monitoring, risk assessment, compliance tracking, and customizable reporting.",
        },
        {
          section: "Benefits Galore",
          subSections: [
            {
              subSectionTitle: "Enhanced Efficiency",
              details:
                "One of the most significant advantages of Internal Audit Software is its ability to automate repetitive tasks, reducing the time and effort required for audits. This efficiency allows auditors to focus on more strategic activities, such as risk assessment and decision-making.",
            },
            {
              subSectionTitle: "Data-Driven Insights",
              details:
                "These software solutions harness the power of data analytics, enabling auditors to extract meaningful insights from large datasets. This data-driven approach helps identify trends, anomalies, and potential risks that may otherwise go unnoticed.",
            },
            {
              subSectionTitle: "Real-Time Monitoring",
              details:
                " Internal Audit Software provides real-time monitoring capabilities, allowing auditors to track key metrics and performance indicators continuously. This proactive approach enables organizations to address issues promptly and make informed decisions.",
            },
            {
              subSectionTitle: "Improved Compliance",
              details:
                " Staying compliant with industry regulations and standards is paramount. Internal Audit Software simplifies compliance management by providing tools for tracking and ensuring adherence to relevant guidelines.",
            },
            {
              subSectionTitle: "Comprehensive Reporting",
              details:
                "These software solutions offer customizable reporting features that empower auditors to create detailed and insightful reports. These reports not only facilitate communication within the organization but also provide valuable information for stakeholders and regulatory bodies.",
            },
          ],
        },
        {
          section: "Choosing the Right Internal Audit Software",
          content:
            "Selecting the appropriate Internal Audit Software for your organization is a critical decision. Factors to consider include scalability, ease of use, integration capabilities, and vendor support. It's essential to align the software's features with your organization's specific needs and goals to maximize its benefits.",
        },
        {
          section: "The Future of Internal Auditing",
          content:
            "As technology continues to evolve, so does Internal Audit Software. The future promises even more advanced features, such as artificial intelligence-driven audits, blockchain-based verification, and seamless integration with other business systems. These advancements will further enhance the role of internal auditors, enabling them to provide deeper insights and strategic guidance to organizations.",
        },
        {
          conclusion:
            "In conclusion, Internal Audit Software has emerged as a game-changer in the world of auditing. Its ability to enhance efficiency, provide data-driven insights, and ensure compliance positions it as an invaluable tool for organizations seeking to thrive in today's complex business environment. As technology continues to advance, the potential for Internal Audit Software to revolutionize the auditing process is limitless, making it an essential asset for modern businesses striving for excellence and confidence in their operations.",
        },
      ],
    },
    {
      title:
        "How People Supply Chain Management Solution Help Businesses to Grow?",
      summary:
        "In today's competitive business landscape, effective supply chain management has become crucial for companies looking to grow and succeed. A key component in this is the people supply chain management solutions.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image2,
      link: "/blog/how-people-supply-chain-management-solution-help-businesses-to-grow",
      details: [
        {
          title:
            "How People Supply Chain Management Solution Help Businesses to Grow?",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "July 3, 2023",
          intro:
            "In today's competitive business landscape, effective supply chain management has become crucial for companies looking to grow and succeed. A key component of successful supply chain management is the integration of people into the system. People supply chain management systems focus on optimizing the human element within the supply chain to enhance efficiency, productivity, and ultimately drive business growth.",
        },
        {
          section: "",
          content:
            "One way people supply chain management systems contribute to business growth is through improved collaboration and communication. By fostering effective communication channels between employees, suppliers, and customers, businesses can streamline operations, reduce delays, and ensure timely delivery of products and services. This leads to increased customer satisfaction and loyalty, ultimately driving business growth.",
        },
        {
          section: "",
          content:
            "Moreover, people supply chain management systems enable companies to identify and nurture talent within the organization. By implementing training programs, career development opportunities, and performance evaluation systems, businesses can maximize the potential of their workforce. Skilled and motivated employees contribute to improved productivity, quality, and innovation, all of which are vital for business growth in today's dynamic market.",
        },
        {
          section: "",
          content:
            "Additionally, people supply chain management systems help businesses adapt to changing market conditions. By staying connected with suppliers and customers, companies can quickly respond to shifts in demand, optimize inventory levels, and adjust production schedules accordingly. This agility allows businesses to seize opportunities and mitigate risks, fostering sustained growth even in volatile markets.",
        },
        {
          section: "",
          content:
            "In conclusion, people supply chain management systems play a crucial role in driving business growth. By focusing on effective communication, talent development, and adaptability, companies can optimize their supply chain operations and gain a competitive edge. Investing in people-centric supply chain management systems not only enhances operational efficiency but also fuels innovation and customer satisfaction, paving the way for sustainable business growth in the long run.",
        },
      ],
    },
    {
      title: "6 Ways E-commerce Websites Help Businesses Grow",
      summary:
        "E-commerce has become an integral part of our daily lives. With the rise of online shopping, e-commerce websites have become a vital tool for businesses looking to expand their reach and increase their revenue.",
      author: "Veda Vyas Harikumar",
      readTime: "3 min read",
      image: image3,
      link: "/blog/6-ways-e-commerce-websites-help-businesses-grow",
      details: [
        {
          title: "6 Ways E-commerce Websites Help Businesses Grow",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "May 10, 2023",
          intro:
            "E-commerce has reshaped the business landscape, offering vast opportunities for growth. This article delves into six key ways e-commerce websites can propel business expansion and revenue growth.",
          section: "Expanded Reach",
          content:
            "The biggest advantage of having an e-commerce website is that it allows businesses to reach customers beyond their local area. With a website, businesses can sell to customers anywhere in the world, which can help to increase their customer base and revenue. An e-commerce website enables businesses to target and sell their products to customers in various locations and countries, thus expanding their reach.",
        },
        {
          section: "Increased Sales",
          content:
            "By selling online, businesses can increase their sales by being open 24/7. This means that customers can shop at any time, from anywhere, without having to worry about store hours or location. Additionally, e-commerce websites make it easy for customers to purchase products, which can increase the likelihood of a sale. With a streamlined checkout process and secure payment gateway, customers can complete purchases quickly and easily.",
        },
        {
          section: "Cost-Effective",
          content:
            "Setting up an e-commerce website is generally more cost-effective than opening a physical store. Businesses can save money on rent, utilities, and other expenses associated with running a brick-and-mortar location. Additionally, businesses can use marketing tools such as email marketing and social media advertising to reach customers without breaking the bank. E-commerce websites can help businesses save money while expanding their reach and boosting their revenue.",
        },
        {
          section: "Improved Customer Experience",
          content:
            "E-commerce websites offer a more convenient shopping experience for customers. Customers can browse products, read reviews, and make purchases all from the comfort of their own home. Additionally, e-commerce websites can provide personalized recommendations based on a customer's browsing and purchase history, which can help to improve the customer experience. By providing a seamless and personalized shopping experience, businesses can build customer loyalty and increase sales.",
        },
        {
          section: "Better Data and Analytics",
          content:
            "With an e-commerce website, businesses can collect valuable data and analytics on customer behavior, such as what products are selling well and which pages on the website are getting the most traffic. This information can be used to make informed decisions about product offerings, pricing, and marketing strategies. By leveraging data and analytics, businesses can optimize their e-commerce website and increase their revenue.",
        },
        {
          section: "Scalability",
          content:
            "E-commerce websites can easily scale to accommodate growth. As a business expands and adds more products, an e-commerce website can handle the additional traffic and inventory without requiring major changes to the website's infrastructure. This scalability makes e-commerce websites a valuable tool for businesses looking to grow and expand their operations.",
        },
        {
          conclusion:
            "In conclusion, e-commerce websites are an essential tool for businesses looking to expand their reach and increase their revenue. By offering a convenient shopping experience, cost-effective marketing strategies, and valuable data and analytics, e-commerce websites help businesses grow and thrive in the digital age. With the benefits of e-commerce, businesses can take advantage of the opportunities provided by online selling and take their operations to the next level.",
        },
      ],
    },
    {
      title: "The Benefits of Workflow Automation for Business Growth",
      summary:
        "Workflow automation has emerged as a key tool for businesses seeking to streamline operations, improve efficiency, and reduce costs. By automating repetitive tasks, companies can focus on growth and innovation.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image4,
      link: "/blog/the-benefits-of-workflow-automation-for-business-growth",
      details: [
        {
          title: "The Benefits of Workflow Automation for Business Growth",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "April 10, 2023",
          intro:
            "Workflow automation has become essential for streamlining operations, enhancing efficiency, and reducing costs. This article outlines how automation of repetitive tasks can catalyze business growth and innovation.",
        },
        {
          section: "Improved Efficiency",
          content:
            "Workflow automation accelerates routine tasks like data entry and document processing, freeing up employees to focus on higher-value tasks. This leads to increased productivity, efficiency, and a more competitive business.",
        },
        {
          section: "Reduced Errors",
          content:
            "Automating manual processes reduces errors, enhances accuracy, and ensures reliable data capture and processing. This improvement fosters better decision-making and business outcomes.",
        },
        {
          section: "Increased Collaboration",
          content:
            "By offering a centralized platform for information sharing, workflow automation enhances collaboration within teams and between departments, improving communication and teamwork.",
        },
        {
          section: "Scalability",
          content:
            "Workflow automation supports business growth by enabling operations to scale without additional staffing, allowing businesses to handle increased workloads efficiently.",
        },
        {
          section: "Better Customer Service",
          content:
            "Automating routine customer service tasks leads to faster response times and more personalized interactions, freeing staff to focus on complex requests and enhancing customer satisfaction.",
        },
        {
          conclusion:
            "Workflow automation is a transformative tool for business growth in today's fast-paced environment. It streamlines operations, cuts costs, boosts efficiency, and improves collaboration, enabling strategic focus on growth and success.",
        },
      ],
    },
    {
      title: "How Artificial Intelligence Can Help Businesses Grow",
      summary:
        "Artificial Intelligence (AI) is revolutionizing business operations and decision-making, offering tools for data analysis, process automation, and customer insights to enhance efficiency and revenue.",
      author: "Veda Vyas Harikumar",
      readTime: "5 min read",
      image: image5,
      link: "/blog/how-artificial-intelligence-can-help-businesses-grow",
      details: [
        {
          title: "How Artificial Intelligence Can Help Businesses Grow",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "March 25, 2023",
          intro:
            "Artificial Intelligence (AI) is transforming business operations and decision-making, enabling companies to leverage machine learning and deep neural networks for data analysis, process automation, and gaining customer insights, leading to enhanced efficiency and increased revenue.",
          section: "Data Analysis and Insights",
          content:
            "Artificial Intelligence (AI) is transforming the way businesses operate and make decisions. By using machine learning algorithms and deep neural networks, AI can help companies to analyze data, automate processes, and gain insights into customer behavior. As a result, businesses can become more efficient, make better decisions, and increase their revenue.",
        },
        {
          section: "Data Analysis and Insights:",
          content:
            "AI algorithms can analyze large volumes of data quickly and accurately, allowing businesses to gain valuable insights into customer behavior, market trends, and operational efficiency. By leveraging these insights, businesses can make data-driven decisions and optimize their operations.",
        },
        {
          section: "Automation: ",
          content:
            "AI can automate repetitive tasks such as data entry, customer service, and financial analysis, freeing up time and resources for more important tasks. Automation can also reduce errors and improve productivity, leading to cost savings and increased efficiency.",
        },
        {
          section: "Personalization: ",
          content:
            "AI can help businesses to personalize their products and services to individual customers, based on their preferences and behavior. This can lead to increased customer loyalty and higher sales.",
        },
        {
          section: "Fraud Detection: ",
          content:
            "AI algorithms can detect patterns of fraudulent behavior in financial transactions, reducing the risk of fraud and saving businesses money.",
        },
        {
          section: "Predictive Maintenance: ",
          content:
            "AI can analyze sensor data from equipment and machinery to predict when maintenance is needed, reducing downtime and maintenance costs.",
        },
        {
          section: "Chatbots and Virtual Assistants: ",
          content:
            "AI-powered chatbots and virtual assistants can improve customer service by providing 24/7 support and answering frequently asked questions.",
        },
        {
          conclusion:
            "In conclusion, AI can help businesses to grow by providing valuable insights, automating tasks, personalizing products and services, detecting fraud, predicting maintenance needs, and improving customer service. As AI technology continues to evolve, it will become increasingly important for businesses to leverage AI to remain competitive and drive growth.",
        },
      ],
    },
    {
      title: "We Build Customized Solution for Building Successful Companies",
      summary:
        "Customized software has become a key tool for businesses in a variety of industries. By creating software tailored to specific needs, businesses can streamline their processes, reduce costs, and gain a competitive edge.",
      author: "Veda Vyas Harikumar",
      readTime: "2 min read",
      image: image6,
      link: "/blog/we-build-customized-solution-for-building-successful-companies",
      details: [
        {
          title:
            "We Build Customized Solution for Building Successful Companies",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "March 20, 2023",
          intro:
            "Customized software is developed specifically for a particular organization or user. It is designed to meet the unique needs of the user and provide a solution that is not available in off-the-shelf software. This type of software can be developed in-house or by a third-party provider.",
        },
        {
          section: "",
          content:
            "There are several benefits to using customized software. First, it can be tailored to meet the specific needs of the user. This means that the software can be designed to meet the specific requirements of a business, rather than trying to fit a business to a pre-existing software solution. This can result in increased efficiency and productivity, as well as cost savings.            ",
        },
        {
          section: "",
          content:
            "Another benefit of customized software is that it can be updated and maintained more easily than off-the-shelf software. This is because the software has been specifically designed for a particular business, so updates and maintenance can be carried out quickly and efficiently, without the need for extensive testing or reconfiguration.",
        },
        {
          section: "",
          content:
            "Customized software also provides a high degree of flexibility. This means that it can be modified and adapted to meet changing business requirements, as well as providing a platform for future growth and development.",
        },
        {
          section: "",
          content:
            "When developing customized software, it is important to work with a reliable and experienced software development company. This will ensure that the software is developed to a high standard and meets the specific needs of the user. The development process should involve consultation with the user to ensure that the software meets their requirements and is user-friendly.",
        },
        {
          section: "",
          content:
            "Testing is also an important part of the development process. This involves carrying out extensive testing to ensure that the software is reliable and meets the requirements of the user. Testing should be carried out at various stages of the development process, from the initial design to the final product.",
        },
        {
          conclusion:
            "In conclusion, customized software can provide businesses with a competitive edge by streamlining processes, reducing costs, and increasing efficiency. By working with an experienced software development company, businesses can develop software that is tailored to their specific needs, and provides a flexible and scalable platform for future growth and development.",
        },
      ],
    },
    {
      title:
        "Process automation helps firms reduce typical manual work while boosting output and overall effectiveness",
      summary:
        "Workflow automation is the process of automating workflow processes using computer software. It has become a crucial part of business operations in recent times, enhancing efficiency and output.",
      author: "Veda Vyas Harikumar",
      readTime: "1 min read",
      image: image7,
      link: "/blog/process-automation-helps-firms-reduce-typical-manual-work-while-boosting-output-and-overall-effectiveness",
      details: [
        {
          title:
            "Process Automation Helps Firms Reduce Typical Manual Work While Boosting Output and Overall Effectiveness",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "March 15, 2023",
          intro:
            "Workflow automation is the process of automating workflow processes using computer software. It has become a crucial part of business operations in recent years as organizations have adopted cloud computing, mobile phones and other technologies to increase productivity.",
        },
        {
          section: "Streamlining Processes",
          content:
            "Workflow automation helps organizations create a more efficient work environment by allowing them to create a shared set of standards among employees who are responsible for specific tasks within their departments. These standards can then be used to automate repetitive tasks that don’t require close supervision or coordination between team members.",
        },
        {
          section: "Automated Processes for Interdepartmental Communication",
          content:
            " Workflow automation allows businesses to create automated email templates that employees can use as reminders or reminders that need follow-up action from someone else in the company. This allows companies to communicate with each other more effectively than ever before, which leads to increased efficiency for everyone involved.",
        },
        {
          section: "Managing Customer Contact Information Effectively",
          content:
            "Managing customer contact information more effectively. Workflow automation allows companies to manage customer information on their websites or through CRM systems easily and efficiently, saving time and money on customer service efforts in the long run",
        },
      ],
    },
    {
      title: "Digital Transformation",
      summary:
        "With the move to digital transformation, companies are now facing the challenge of replacing their manual processes with automated ones. This shift is driving a new era of efficiency and productivity.",
      author: "Veda Vyas Harikumar",
      readTime: "1 min read",
      image: image8,
      link: "/blog/digital-transformation",
      details: [
        {
          title: "Digital Transformation",
          author: "Veda Vyas Harikumar",
          profile:
            "Global Business Operations Leader | Strategic Alliances & Business Management Expert | International Market Bidding Specialist | Devoted to Driving Digital Transformation for Companies",
          publicationDate: "March 11, 2023",
          intro:
            "With the move to digital transformation, companies are now facing the challenge of replacing their manual processes with automated ones. This shift is not only beneficial in terms of cost savings and improved efficiency, but also in terms of customer experience. By introducing digital tools into their operations, companies can provide customers with a significantly enhanced user experience, Digital transformation means replacing manual processes with automated ones, which requires a change management strategy to ensure that all stakeholders are on board with this change. Companies need to consider how they are going to provide training and support to employees so they can effectively utilize the new technology and adapt their working practices accordingly. Furthermore, measures must be taken to ensure that the transition from manual process to digital transformation is seamless and without disruption.",
        },
      ],
    },
  ];

  const location = useLocation();
  const blogId = location.pathname.split("/")[2];

  const blog = blogData.find((b) => b.link === `/blog/${blogId}`);

  return (
    <div className="  flex justify-center">
      <div className=" flex flex-col  max-w-screen-xl">
        <div className="  w-full  mb-0  lg:mb-10  ">
          {" "}
          <div className="  w-full flex justify-center items-center  ">
            <img
              alt={blog.title}
              src={blog.image}
              className=" object-cover w-2/3 rounded-lg   mb-4"
            />
          </div>
          {blog.details.map((detail, index) => (
            <div key={index} className="mt-4 px-5">
              <h2 className="text-xl font-semibold text-black my-2">
                {detail.section || detail.title}
              </h2>
              <p className=" text-sm lg:text-base text-gray-600">
                {detail.content || detail.intro}
              </p>

              {detail.subSections?.map((subSection, subIndex) => (
                <div key={subIndex} className="mt-3">
                  <h3 className="text-lg font-semibold">
                    {subSection.subSectionTitle}
                  </h3>
                  <p className=" text-sm lg:text-base text-gray-600">
                    {subSection.details}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* <BlogCards /> */}
      </div>
    </div>
  );
}
