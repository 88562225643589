// import footer from "../assets/footer.svg";
import { useRef } from "react";
import footer_white from "../assets/footer_white.svg";
import cloud_dark from "./assets/cloud_dark.svg";
import useOnScreen from "../utils/UseOnScreen";
import location from "./assets/location.png";
import mail from "./assets/mail.png";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import dp from "./assets/toyo logo dp2.png";
import updatedLogo from "./assets/toyoUpdatedLogo.png";

export default function Footer() {
  const ref = useRef();

  const isVisible = useOnScreen(ref);
  const controls = useAnimation();

  if (isVisible) {
    controls.start("visible");
  }
  const text =
    "We are a prominent provider of business solutions, facilitating operational automation to achieve business objectives with superior compliance, governance, and overall control over the enterprise.";

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.009,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  function Section({ title, children }) {
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { y: -80, opacity: 0 },
          visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1 },
          },
        }}
        className={`flex w-full items-start     ${
          title === "Services" ? "lg:pr- pr-0 " : "lg:pl-[50px] pl-0 "
        }`}
      >
        <div className="flex flex-col w-full  gap-2 lg:gap-6 items-start">
          <div className="text-[#eaeaea]  text-base lg:text-2xl underline underline-offset-8 font-bold focus:text-red-700">
            {title}
          </div>
          <div className="flex flex-col w-full  gap-1  lg:gap-3">
            {children}
          </div>
        </div>
      </motion.div>
    );
  }
  function MenuItem({ children, item, link }) {
    return (
      <motion.div
        onClick={() => navigate(link)}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { y: 120, opacity: 0 },
          visible: isVisible && {
            y: 0,
            opacity: 1,

            transition: { duration: 1 + item / 8 },
          },
        }}
        className="text-white lg:text-base text-sm  w-fit hover:text-[#34C7A3] cursor-pointer hover:underline"
      >
        {children}
      </motion.div>
    );
  }
  const navigate = useNavigate();
  return (
    <div className="w-full  flex-col flex justify-center items-center">
      <div
        style={{
          // backgroundImage: `url(${footer})`,
          backgroundColor: "#0e2146",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="w-full  h-full lg:h-[400px] relative flex items-end"
      >
        <div
          className="absolute h-[300px] -z-10 -top-32 left-0 right-0 "
          style={{
            backgroundImage: `url(${footer_white})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div
          ref={ref}
          className="w-full z-30 flex justify-center items-center  "
        >
          <div className="  pt-10 pb-4  pl-4 w-full">
            <div className=" flex w-full gap-7 lg:gap-0   lg:px-10 flex-wrap lg:flex-nowrap items-start justify-start ">
              <div className=" flex w-full   justify-center items-center">
                <div className=" flex flex-col w-full  gap-5 justify-center items-center">
                  {
                    <style jsx>
                      {`
                        .perspective-tilt {
                          transform: perspective(500px) rotateY(0deg)
                            rotateX(90deg);
                          transition: transform 0.3s ease-in-out;
                        }
                      `}
                    </style>
                  }
                  <div className="w-full flex flex-col justify-start  ">
                    <motion.div
                      initial={{ rotateY: 0, rotateX: 90 }}
                      animate={
                        isVisible
                          ? { rotateY: 0, rotateX: 0 }
                          : { rotateY: 0, rotateX: 90 }
                      }
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      className="flex justify-start rounded-md  perspective-tilt py-2 bg-[#ffffff00] items-center w-full "
                    >
                      <img
                        width={250}
                        src={
                          "https://zodiacmatctest.s3.amazonaws.com/Untitled+design+(3).png"
                        }
                        alt="logo"
                        className=""
                      />
                    </motion.div>
                    <div className=" text-white whitespace-nowrap  text-start text-xl">
                      Innovative Growth & Transformation
                    </div>
                  </div>
                  <div className="text-justify pr-8 w-full items-center flex text-sm justify-center text-[#fff]">
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={containerVariants}
                    >
                      {text.split("").map((char, index) => (
                        <motion.span
                          className=" text-sm"
                          key={index}
                          variants={itemVariants}
                        >
                          {char}
                        </motion.span>
                      ))}
                    </motion.div>
                  </div>
                  <div className=" flex gap-4 items-center justify-start w-full ">
                    <motion.a
                      href="https://www.linkedin.com/company/toyo-digital-innovation/"
                      target="_blank"
                      initial="hidden"
                      animate={controls}
                      variants={{
                        hidden: { y: 80, opacity: 0 },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        },
                      }}
                      className=" hover:translate-y-1 bg-[#34C7A3] p-1 rounded-full 
                      w-[40px] h-[40px] 
                    flex justify-center items-center "
                    >
                      <i
                        class="fab fa-linkedin white"
                        style={{
                          color: "#fff",
                          width: "22px",
                          height: "22px",
                        }}
                      ></i>
                    </motion.a>
                    <motion.a
                      href="https://twitter.com/DigitalToyo"
                      target="_blank"
                      initial="hidden"
                      animate={controls}
                      variants={{
                        hidden: { y: 80, opacity: 0 },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        },
                      }}
                      className=" hover:translate-y-1 bg-[#34C7A3] p-1 rounded-full 
                      w-[40px] h-[40px] 
                    flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        class="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                      </svg>
                    </motion.a>
                    <motion.a
                      href="https://www.facebook.com/toyodigitalinnovation"
                      target="_blank"
                      initial="hidden"
                      animate={controls}
                      variants={{
                        hidden: { y: 80, opacity: 0 },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        },
                      }}
                      className=" hover:translate-y-1 bg-[#34C7A3] p-1 rounded-full 
                      w-[40px] h-[40px] 
                    flex justify-center items-center "
                    >
                      <i
                        class="fab fa-facebook-f white"
                        style={{
                          color: "#fff",
                          width: "22px",
                          height: "22px",
                        }}
                      ></i>
                    </motion.a>
                    <motion.a
                      href="https://www.instagram.com/toyodigitalinnovation"
                      target="_blank"
                      initial="hidden"
                      animate={controls}
                      variants={{
                        hidden: { y: 80, opacity: 0 },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        },
                      }}
                      className=" hover:translate-y-1 bg-[#34C7A3] p-1 rounded-full 
                      w-[40px] h-[40px] 
                    flex justify-center items-center "
                    >
                      <i
                        class="fab fa-instagram white"
                        style={{
                          color: "#fff",
                          width: "22px",
                          height: "22px",
                        }}
                      ></i>
                    </motion.a>
                    <motion.a
                      href="https://www.youtube.com/@TOYODigitalInnovation"
                      target="_blank"
                      initial="hidden"
                      animate={controls}
                      variants={{
                        hidden: { y: 80, opacity: 0 },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: { duration: 0.3 },
                        },
                      }}
                      className=" hover:translate-y-1 bg-[#34C7A3] p-1 rounded-full 
                      w-[40px] h-[40px] 
                    flex justify-center items-center "
                    >
                      <i
                        class="fab fa-youtube white"
                        style={{
                          color: "#fff",
                          width: "22px",
                          height: "22px",
                        }}
                      ></i>
                    </motion.a>
                  </div>
                </div>
              </div>
              <Section title="Company">
                <MenuItem link="/" item="1">
                  Home
                </MenuItem>
                <MenuItem link="/about" item="2">
                  About Us
                </MenuItem>
                <MenuItem link="/blog" item="3">
                  Blog
                </MenuItem>
                <MenuItem link="/services" item="4">
                  Services
                </MenuItem>
                <MenuItem link="/contact" item="5">
                  Contact Us
                </MenuItem>
              </Section>

              <Section title="Services">
                <MenuItem link="/services" item="1">
                  Custom Software Development
                </MenuItem>
                <MenuItem link="/services" item="2">
                  Mobile Application
                </MenuItem>
                <MenuItem link="/services" item="3">
                  ISO Management Solution
                </MenuItem>
                <MenuItem link="/services" item="4">
                  Website Design & Development
                </MenuItem>
                <MenuItem link="/services" item="5">
                  Cyber Security & Network Configuration
                </MenuItem>
              </Section>

              <motion.div
                initial="hidden"
                animate={controls}
                variants={{
                  hidden: { y: 80, opacity: 0 },
                  animate: { controls },
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.9 },
                  },
                }}
                className="  relative items-start justify-start lg:flex  hidden  w-1"
              >
                <div className="h-[280px] w-[1px] bg-white   "></div>
              </motion.div>
              <div className=" flex w-full  lg:pl-8 pl-0  items-start">
                <div className=" flex flex-col w-full    lg:gap-6   items-start">
                  <div className=" text-[#eaeaea] underline underline-offset-8   text-base lg:text-2xl font-bold   focus:text-red-700 ">
                    Contact Us
                  </div>
                  <div className=" flex flex-col w-full pt-2   gap-2  lg:gap-3   ">
                    <div className=" flex gap-2  ">
                      <motion.div
                        initial={{ x: -30, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className=" mt-[6px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#34C7A3"
                          class="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                      </motion.div>
                      <motion.div
                        initial={{ x: 0, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className="text-white pr-8 w-full text-sm lg:text-base  hover:text-[#34C7A3] cursor-pointer  hover:underline "
                      >
                        Toyo Digital Innovation Pvt Ltd Brigade Meadows ,
                        Udayapura Post , Kanakapura Road, Bangalore, India-
                        560082
                      </motion.div>
                    </div>
                    <div className=" flex gap-2 ">
                      <motion.div
                        initial={{ x: -30, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className=" mt-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#34C7A3"
                          class="bi bi-telephone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                          />
                        </svg>
                      </motion.div>
                      <motion.a
                        href="tel:+917676638635"
                        initial={{ x: 0, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className="text-white w-full text-sm lg:text-base  hover:text-[#34C7A3] cursor-pointer  hover:underline "
                      >
                        +91 767 663 8635
                      </motion.a>
                    </div>
                    <div className=" flex gap-2  ">
                      <motion.div
                        initial={{ x: -30, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className=" mt-[6px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#34C7A3"
                          class="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                      </motion.div>
                      <motion.div
                        initial={{ x: 0, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className="text-white pr-8 w-full text-sm lg:text-base  hover:text-[#34C7A3] cursor-pointer  hover:underline "
                      >
                        Melbourne, Clayton - Australia
                      </motion.div>
                    </div>
                    <div className=" flex gap-2 ">
                      <motion.div
                        initial={{ x: -30, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className=" mt-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#34C7A3"
                          class="bi bi-telephone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                          />
                        </svg>
                      </motion.div>
                      <motion.a
                        href="tel:+61455165166"
                        initial={{ x: 0, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className="text-white w-full text-sm lg:text-base  hover:text-[#34C7A3] cursor-pointer  hover:underline "
                      >
                        +61 455 165 166
                      </motion.a>
                    </div>

                    <div className=" flex gap-2 ">
                      <motion.div
                        initial={{ x: -30, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className=" mt-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#34C7A3"
                          class="bi bi-envelope-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                      </motion.div>
                      <motion.a
                        href="mailto:info@toyodigitalinnovation.com"
                        initial={{ x: 0, opacity: 0 }}
                        animate={isVisible && { x: 0, opacity: 1 }}
                        transition={{ duration: 0.9 }}
                        className="text-white w-full text-sm lg:text-base  hover:text-[#34C7A3] cursor-pointer  hover:underline "
                      >
                        info@toyodigitalinnovation.com
                      </motion.a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" absolute bottom-0 w-ful  -z-0 left-0 ">
          <img src={cloud_dark} alt="" />
        </div>
        <div className="absolute">
          <FloatingWhatsApp
            showPopup={false}
            phoneNumber="+919663636363"
            accountName="TOYO DIGITAL INNOVATION"
            statusMessage="Available"
            avatar={dp}
          />
        </div>
      </div>
      {/* Copyright © 2023 Toyo Digital Innovation, Inc. All rights reserved. */}
      <div
        className=" w-full 
        bg-[#0e2146] flex-col  flex justify-center items-center
      "
      >
        <hr
        className=" w-full bg-white"
  />
          
        <div className=" bg-[#0e2146] text-white w-full text-center items-center flex justify-center font-semibold py-3 ">
          Copyright © 2023 Toyo Digital Innovation, Inc. All rights reserved.
        </div>
      </div>
    </div>
  );
}
