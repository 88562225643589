import { motion } from "framer-motion";

const getAnimationProps = (isImage, isHovered) => {
  return isImage
    ? {
        initial: { y: 0, scale: 1, opacity: 1 },
        animate: isHovered
          ? { y: -100, scale: 0, opacity: 0 }
          : { y: 0, scale: 1, opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.5 },
      }
    : {
        initial: { position: "absolute", y: 50, opacity: 0 },
        animate: isHovered ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 },
        exit: { opacity: 0 },
        transition: { duration: 0.5 },
      };
};

const PortfolioCard = ({ item, index, isHovered }) => (
  <motion.div

    className={`group flex p-5 ${isHovered ? "bg-[#0f1438]  shadow-2xl   shadow-blue-800 " : " bg-white"}  transition-all duration-500   relative rounded-md  shadow-md shadow-gray-300 border w-[300px] min-h-[350px] flex-col items-center ${
      index === 0 && "mt-5"
    }`}
  >
    <motion.div
      {...getAnimationProps(true, isHovered)}
      style={{
        backgroundImage: `url(${item.image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="w-full h-[250px] flex justify-center items-center"
    >
      <h1 className="text-xl font-bold text-[#0f1438] text-center mt-[320px]">
        {item.title}
      </h1>
    </motion.div>
    <motion.div
      {...getAnimationProps(false, isHovered)}
      className="w-full h-[150px] flex-col flex justify-center -top-[100px] absolute items-center mt-5  "
    >
      <h1 className="text-xl font-bold text-[#fff] text-center  px-4 mt-[370px]">
        {item.title}
      </h1>
      <p className="text-white text-justify mt-3 px-7 text-[15px]">
        {item.description}
      </p>
    </motion.div>
  </motion.div>
);

export default PortfolioCard;
